import React, { Component } from 'react';


export default class TabsLine extends Component {
  render() {
    return(
      <div className={'tabs-line'}>
        {(this.props.options).map((tab, index) => {
          return <button
            className={'tab-line' + (tab.active === true ? ' active' : '')}
            onClick={tab.onClick}
            disabled={tab.disabled}
            key={index}
          >
            {tab.text}
          </button>;
        })}
      </div>
    )
  }
}
