import React, { Component } from 'react';

import Icon from '@material-ui/core/Icon';

import AcmiForm from '../components/AcmiForm';
import BoxHeader from '../components/BoxHeader';
import BusForm from '../components/BusForm';
import HotelsForm from '../components/HotelsForm';
import LogisticsForm from '../components/LogisticsForm';
import LinkButton from '../components/LinkButton';
import MomentSelector from '../components/MomentSelector';
import OtaForm from '../components/OtaForm';

import axios from 'axios';

export default class HomeScreen extends Component {

  constructor() {
      super()
      this.state = {
          dataApi: [],
          acmiSelected: true,
          hotelsSelected: true,
          busSelected: true,
          logisticsSelected: true,
          otaSelected: true,
          dateSearch: new Date(),
      }
  }

  componentDidMount() {

      axios.post('https://saflyapi.omatech.com/api/demo/napoles?api_key=key_cur_prod_fnPqT5xQEi5Vcb9wKwbCf65c3BjVGyBB', {
          headers: {
              'Content-Type': 'application/json',
          },
      }).then(res => {
          const dataApi = res.data;
          this.setState({ dataApi });
      }).catch(function (error) {
      });
  };

  handleBoxes = (boxName) => (e) => {
    this.setState({
      [boxName]: e.target.checked
    })
  }

  getDateA = async (value) => {
    await this.setState({ dateSearch: value });
  }

  render() {

    return(
      <main className="home-screen image-bg">
        <form action="" className={'form'}>
          <div className="container">
            <div className="title-group">
              <p className="title-lg">Where can we help today?</p>
              <p className="title-md">The most powerful way to solve disruptive events</p>
            </div>

            <MomentSelector
              getDateA={ this.getDateA }
              />

            <div className="boxes-row">
              {/* ACMI BOX */}
              <div className={'box acmi-box' + (this.state.acmiSelected ? '' : ' disabled')}>
                <BoxHeader
                  iconName={'flight_departure'}
                  title={'ACMI'}
                  tooltipDisabled={!this.state.acmiSelected}
                  switchInput={true}
                  checked={this.state.acmiSelected}
                  onChange={this.handleBoxes('acmiSelected')}
                  infoText={"With our leading airline providers, we are ready to find subcharter solutions in a matter of minutes. If you have an AOG without an internal solution, we can provide you our range of availabilities in real time and costs."}
                />
                <AcmiForm
                  boxSelected={this.state.acmiSelected}
                  dataFromParent = {this.state.dataApi}
                />
              </div>

              {/* HOTELS BOX */}
              <div className={'box hotels-box' + (this.state.hotelsSelected ? '' : ' disabled')}>
                <BoxHeader
                  iconName={'hotel_bed'}
                  title={'HOTELS'}
                  tooltipDisabled={!this.state.hotelsSelected}
                  switchInput={true}
                  checked={this.state.hotelsSelected}
                  onChange={this.handleBoxes('hotelsSelected')}
                  infoText={"Depending on the disruption, sometimes passengers must spend an overnight, as there is no feasible solution from the airline to transport them to their destinations. We have a range of hotels near the airport to give a fast solution."}
                />
                <HotelsForm
                  boxSelected={this.state.hotelsSelected}
                  dataFromParent = {this.state.dataApi}
                />
              </div>

              {/* BUS BOX */}
              <div className={'box bus-box' + (this.state.busSelected ? '' : ' disabled')}>
                <BoxHeader
                  iconName={'bus'}
                  title={'TRANSPORT'}
                  tooltipDisabled={!this.state.busSelected}
                  switchInput={true}
                  checked={this.state.busSelected}
                  onChange={this.handleBoxes('busSelected')}
                  infoText={"If an overnight is planned at the destination airport or the route is short enough that can be done by bus, we provide instant quotation and routing from our bus providers in order to accommodate all passengers."}
                />
                <BusForm
                  boxSelected={this.state.busSelected}
                  dataFromParent = {this.state.dataApi}
                />
              </div>

              {/* LOGISTICS BOX */}
              <div className={'box logistics-box' + (this.state.logisticsSelected ? '' : ' disabled')}>
                <BoxHeader
                  iconName={'cog_double'}
                  title={'LOGISTICS'}
                  tooltipDisabled={!this.state.logisticsSelected}
                  switchInput={true}
                  checked={this.state.logisticsSelected}
                  onChange={this.handleBoxes('logisticsSelected')}
                  infoText={"Sometimes an AOG can ground a plane in an isolated airport where the airline must send a maintenance team to fix the plane. Thanks to our logistic knowledge we offer the fastest solution, and at the same time the cheapest."}

                />
                <LogisticsForm
                  boxSelected={this.state.logisticsSelected}
                  dataFromParent = {this.state.dataApi}
                />
              </div>

              {/* OTA BOX */}
              <div className={'box ota-box' + (this.state.otaSelected ? '' : ' disabled')}>
                <BoxHeader
                  iconName={'store'}
                  title={'OTA'}
                  tooltipDisabled={!this.state.otaSelected}
                  switchInput={true}
                  checked={this.state.otaSelected}
                  onChange={this.handleBoxes('otaSelected')}
                  infoText={"In the event of finding a subcharter with smaller capacity or not able to find an internal solution, we provide an OTA ready to find scheduled flights as fast as possible with other regular carriers, to accommodate all passengers at a blink."}

                />
                <OtaForm
                  boxSelected={this.state.otaSelected}
                  dataFromParent = {this.state.dataApi}
                />
              </div>

            </div>

            <div className={'submit-row'}>
              <div className={'advice'}>
                <Icon fontSize={'large'}>info_outline</Icon>
                <p className={'advice-text'}>Use the top right switches to deactivate the services that you don't need to solve</p>
              </div>
              <div className={'buttons-holder'}>
                <span className={'term'}>Search for:</span>
                <LinkButton
                  value="fastest"
                  date={this.state.dateSearch}
                  color={'primary'}
                  iconName={'clock'}
                  text={'Fastest solutions'}
                  to={'/results'}
                />
                <LinkButton
                  value="price"
                  date={this.state.dateSearch}
                  color={'green'}
                  iconName={'money_coins'}
                  text={'Best price solutions'}
                  to={'/results'}
                />
              </div>
            </div>

          </div>
        </form>
      </main>
    )
  }
}
