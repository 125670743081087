import React, { Component } from 'react';

import Icon from '@material-ui/core/Icon';
import FormGroup from '@material-ui/core/FormGroup';

import FormCheckbox from '../components/FormCheckbox';

export default class StarCheckboxes extends Component {
  render() {
    return(
      <FormGroup className={'star-checkboxes'}>
        <FormCheckbox
          disabled = {this.props.disableAllCheckboxes}
          label={
            <div className={'stars-row'}>
              <Icon>star</Icon>
            </div>
          }
        />
        <FormCheckbox
          disabled = {this.props.disableAllCheckboxes}
          label={
            <div className={'stars-row'}>
              <Icon>star</Icon>
              <Icon>star</Icon>
              <Icon>star</Icon>
              <Icon>star</Icon>
            </div>
          }
        />
        <FormCheckbox
          disabled = {this.props.disableAllCheckboxes}
          label={
            <div className={'stars-row'}>
              <Icon>star</Icon>
              <Icon>star</Icon>
            </div>
          }
        />
        <FormCheckbox
          disabled = {this.props.disableAllCheckboxes}
          label={
            <div className={'stars-row'}>
              <Icon>star</Icon>
              <Icon>star</Icon>
              <Icon>star</Icon>
              <Icon>star</Icon>
              <Icon>star</Icon>
            </div>
          }
        />
        <FormCheckbox
          disabled = {this.props.disableAllCheckboxes}
          label={
            <div className={'stars-row'}>
              <Icon>star</Icon>
              <Icon>star</Icon>
              <Icon>star</Icon>
            </div>
          }
        />
      </FormGroup>
    )
  }
}
