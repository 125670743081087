import React, { Component } from 'react';

import Icon from '@material-ui/core/Icon';

export default class SquareIconButton extends Component {
  render() {
    const { disabled, fontSize, iconName, onClick, style } = this.props;
    return <button
      className={'square-icon-btn'}
      disabled={disabled}
      onClick={onClick}
      style={style}
    >
      <Icon fontSize={fontSize}>{iconName}</Icon>
    </button>
  }
}
