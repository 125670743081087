import React, { Component } from 'react';

import FormLabel from '../components/FormLabel';
import LabelGroup from '../components/LabelGroup';

export default class AcmiBoxResume extends Component {

    constructor(props) {
        super(props)
        this.state = {
        }
    }

    componentDidUpdate() {
    };

  render() {

    return(
      <div className={'box-body'} style={{ height: this.props.bodyHeight }}>
        <div className="form-group">
          <LabelGroup
            iconName={'flight_departure'}
            text={'Departure'}
          />
          <p>{this.props.dataApi ? (this.props.dataApi['DEPARTURE']) : ('')}</p>
        </div>
        <div className="form-group">
          <LabelGroup
            iconName={'flight_arrival'}
            text={'Arrival'}
          />
          <p>{this.props.dataApi ? (this.props.dataApi['ARRIVAL']) : ('')}</p>
        </div>
        <div className="form-group">
          <LabelGroup
            iconName={'person_standing'}
            text={'PAX'}
          />
          <p>{this.props.dataApi ? (this.props.dataApi['PAX']) : ('')}</p>
        </div>
        <div className="form-group percent-40">
          <FormLabel title={'Min YOM'} />
          <p>{this.props.dataApi ? (this.props.dataApi['MIN_YOM']) : ('')}</p>
        </div>
        <div className={'form-row'}>
          <div className="form-group percent-60">
            <FormLabel title={'Preferred Model'} />
            <p>{this.props.dataApi ? (this.props.dataApi['PREFERRED_MODEL']) : ('')}</p>
          </div>
          <div className="form-group percent-40">
            <FormLabel title={'IOSA'} />
            <p>{this.props.dataApi ? (this.props.dataApi['IOSA']) : ('')}</p>
          </div>
        </div>
      </div>
    )
  }
}
