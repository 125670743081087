import React, { Component } from 'react';

import withStyles from '@material-ui/core/styles/withStyles';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';



export default class InfoTooltip extends Component {
  render() {
    const LightTooltip = withStyles(theme => ({
      tooltip: {
        backgroundColor: '#FFFFFF',
        color: '#3A3A3B',
        boxShadow: '0 0 14px 0 rgba(0,0,0,0.18)',
        fontSize: 12,
        fontWeight: 400,
        fontFamily: 'Open Sans',
        padding: 10
      },
    }))(Tooltip);

    return(
      <LightTooltip title={this.props.title}>
        <span>
          <IconButton
            aria-label={'info'}
            color={'default'}
            disabled ={ this.props.disabled }
            style={{ padding: 0 }}
          >
            <Icon fontSize={'small'}>info_outline</Icon>
          </IconButton>
        </span>

      </LightTooltip>
    )
  }
}
