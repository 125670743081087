import React, { Component } from 'react';

import FormControl from '../components/FormControl';
import FormLabel from '../components/FormLabel';
import LabelGroup from '../components/LabelGroup';
import ResultRow from '../components/ResultRow';
import SingleSelect from '../components/SingleSelect';
import SquareIconButton from './SquareIconButton';
import StarCheckboxes from '../components/StarCheckboxes';

export default class HotelsForm extends Component {

  constructor(props) {
    super(props)

    this.displayListData = [];

    this.state = {
      showListData: this.displayListData,
      paxListdata: [],

      listIndex: 0,

      hotelstotal: 0,
      hotelspax: '',
      hotelsrooms: '',
    }

    this.appendData = this.appendData.bind(this);
  }

  handleValue = (boxName) => (e) => {
    this.setState({
      [boxName]: e.target.value
    })
  }

  handleOptions = (boxName) => (e) => {
    this.setState({
      [boxName]: e.label
    })
    this.setState({
      [boxName+'value']: e.value
    })
  }

  deleteHotelsRow = (id) => (e) => {
    e.preventDefault();
    var value = this.state.paxListdata[id];
    var showlist = this.state.showListData;
    showlist[id] = []

    this.setState({
      showListData: showlist,
      hotelstotal: this.state.hotelstotal - parseInt(value)
    });
  }

  appendData(e) {
    e.preventDefault();

    var mult = 1;
    if(this.state.hotelsrooms === 'For 2'){
      mult = 2;
    }else if(this.state.hotelsrooms === 'For 3'){
      mult = 3;
    }else if( this.state.hotelsrooms === 'For 4'){
      mult = 4;
    }else{
      mult = 1;
    }

    if( this.state.hotelspax){
      this.setState({
        hotelstotal: this.state.hotelstotal + (parseInt(this.state.hotelspax)*mult)
      })
    }
    this.state.paxListdata.push( parseInt(this.state.hotelspax)*mult );

    this.displayListData.push(
      <ResultRow
        onClick={this.deleteHotelsRow((this.state.listIndex))}
        key={this.state.listIndex}
        description={this.state.hotelsrooms}
        term={this.state.hotelspax ? (parseInt(this.state.hotelspax)*mult) + ' PAX' : ''}
        removable={true}
      />
    );

    this.setState({
      showListData : this.displayListData,
      listIndex: this.state.listIndex + 1
    });
  }

  render() {

    const airportSelectOptions = []
    const roomSelectOptions = []
    var i = 0;
    if (typeof this.props.dataFromParent.Airports != "undefined"){
        for (i = 0; i < this.props.dataFromParent.Airports.length; i++) {
            airportSelectOptions.push( {value:'cit'+i, label: this.props.dataFromParent.Airports[i]} )
        }
        for (i = 0; i < this.props.dataFromParent.RoomType.length; i++) {
            roomSelectOptions.push( {value:'rt'+i, label: this.props.dataFromParent.RoomType[i]} )
        }
    }

    return(
      <div className="box-body">
        <div className={'form-group'}>
          <LabelGroup
            iconName={'city'}
            text={'Airport'}
          />
          <SingleSelect
            disabled={!this.props.boxSelected}
            options={airportSelectOptions}
          />
        </div>
        <div className={'form-row'}>
          <div className={'form-group percent-50'}>
            <LabelGroup
              iconName={'location_pin_area'}
              text={'Km range'}
            />
            <FormControl
              disabled={!this.props.boxSelected}
              type={'number'}
            />
          </div>
        </div>
        <div className={'form-group'}>
          <LabelGroup
            iconName={'star'}
            text={'Categories'}
          />
          <StarCheckboxes disableAllCheckboxes={!this.props.boxSelected} />
        </div>

        <div className={'form-row add-row'}>

          <div className={'form-group percent-40'}>
            <LabelGroup
              iconName={'hotel_bed'}
              text={'Rooms'}
            />
            <FormControl
              disabled={!this.props.boxSelected}
              type={'number'}
              name={'hotelspax'}
              onChange={this.handleValue('hotelspax')}
            />
          </div>

          <div className={'form-group percent-60'}>
            <FormLabel title={'Type'} style={{ paddingTop: 2 }} />
            <SingleSelect
              disabled={!this.props.boxSelected}
              options={roomSelectOptions}
              name={'hotelsrooms'}
              onChange={this.handleOptions('hotelsrooms') }

            />
          </div>
          <SquareIconButton
            disabled={!this.props.boxSelected}
            iconName={'add'}
            onClick={this.appendData}
          />

        </div>
        <div className="results-list">
          {this.displayListData}
        </div>

        <h2 className="total-title">Total</h2>

        <ResultRow
          description={''}
          term={this.state.hotelstotal + ' PAX'}
        />
      </div>
    )
  }
}
