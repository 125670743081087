import React, { Component } from 'react';

import Icon from '@material-ui/core/Icon';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';

import CustomCalendar from '../components/CustomCalendar';

export default class MomentSelector extends Component {

  constructor() {
    super()
    this.state = {
      selected: 'now',
      calendarDisabled: true
    }
  }

  handleChange = ev => {
    this.setState({ selected: ev.target.value });
    if( this.state.selected === 'date' ) {
      this.setState({ calendarDisabled: true })
    }
    else {
      this.setState({ calendarDisabled: false })
    }
  };

  render() {
    const { selected, calendarDisabled } = this.state;
    return(
      <RadioGroup className={'moment-selector'} onChange={this.handleChange} value={ selected }>
        <div className="box">
          <Radio
            checkedIcon={<Icon>check_circle</Icon>}
            disableRipple
            value={'now'}

          />
          <span className="radio-label">Now</span>
        </div>
        <span className={'or'}>Or</span>
        <div className="box">
          <Radio
            checkedIcon={<Icon>check_circle</Icon>}
            disableRipple
            value={'date'}
          />
          <span className="radio-label">Date</span>
          <div className={'form-group'}>
            <div className={'input-group'}>

              <CustomCalendar
                disabled={ calendarDisabled }
                selectsStart
                getDateA={ this.props.getDateA }
              />

              <div className={'append'}>
                <Icon fontSize={'small'} color={'disabled'}>calendar_today</Icon>
              </div>
            </div>
          </div>
        </div>
      </RadioGroup>
    )
  }
}
