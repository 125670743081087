import React, { Component } from 'react';

import FormControl from '../components/FormControl';
import FormLabel from '../components/FormLabel';
//import LinkButton from '../components/LinkButton';
import Button from '../components/Button'

const clientNames = [
  'aireuropa',
  'airnostrum',
  'albastar',
  'privilegestyle',
  'vueling',
  'volotea'
]

export default class LoginScreen extends Component {
  state = {
    formHasErrors: false,
  }

  handleSubmit = (e) => {
    e.preventDefault();
    const username = document.querySelector('[name="username"]').value;
    const password = document.querySelector('[name="password"]').value;
    var usernameIsValid = false;
    var passwordIsValid = false;

    if( username !== '' ) {
      usernameIsValid = true;
    }

    for( var i = 0; i < clientNames.length; i++ ) {
      if( clientNames[i] === password ) {
        passwordIsValid = true;
      }
    }

    if ( usernameIsValid && passwordIsValid ) {
      this.setState({
        formHasErrors: false
      })
      this.props.functionCallFromParent(password);
    }

    else {
      this.setState({
        formHasErrors: true
      })
    }
  }


  render() {
    return(
      <main className="login-screen" >
        <div className="container">

          <div className="title-group">
            <p className="title-lg">Where can we help today?</p>
            <p className="title-md">The most powerful way to solve disruptive events</p>
          </div>

          <div className="box login-box">
            <h1 className="title-md">Log in with your account</h1>
            <form action="" className="form login-form" onSubmit={this.handleSubmit}>
              <div className="form-group">
                <FormLabel title={'Username/Email'} />
                <FormControl placeholder={'Insert your username or email'} size={'large'} name={'username'} />
              </div>
              <div className="form-group">
                <FormLabel title={'Password'} />
                <FormControl type={'password'} placeholder={'Insert your password'} size={'large'} name={'password'} />
              </div>
              <a href="/" className={'forgot-password'}>I’ve forget my password</a>
              <br />
              <Button type={'submit'} text={'Start'} color={'orange'} block />
              {
                this.state.formHasErrors
                ? <p className={'validation-error'}>Username or password incorrect. Please, try again.</p>
                : ''
              }
              {/*<LinkButton
                style={{ display: 'none' }}
                to={'/home'} text={'Start'}
                color={'orange'}
                block
              />*/}
            </form>
          </div>
        </div>

        <span className="bg-video">
          {/* replace with the video src */}
          <img className={'video'} src={require('../img/bg-airport.jpg')} alt=""/>
        </span>
      </main>
    )
  }
}
