import React, { Component } from 'react';

import Icon from '@material-ui/core/Icon';

import LabelGroup from '../components/LabelGroup';

export default class OtaBoxResume extends Component {
  render() {
    return(
      <div className={'box-body'} style={{ height: this.props.bodyHeight }}>
        <div className="form-group">
          <LabelGroup
            iconName={'flight_departure'}
            text={'Departure'}
          />
          <p>NTE - Nantes</p>
        </div>
        <div className="form-group">
          <LabelGroup
            iconName={'flight_arrival'}
            text={'Arrival'}
          />
          <p>BCN - Barcelona El Prat</p>
        </div>
        <div className={'form-row'}>
          <div className="form-group percent-40">
            <LabelGroup
              iconName={'person_standing'}
              text={'PAX'}
            />
            <p>180</p>
          </div>
        </div>
        <div className={'ico-list-item'}>
          <Icon>check</Icon>
          <p>Nearby airports</p>
        </div>
        <div className={'ico-list-item'}>
          <Icon>close</Icon>
          <p>Only direct flights</p>
        </div>
      </div>
    )
  }
}
