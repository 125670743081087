import React, { Component } from 'react';

import Icon from '@material-ui/core/Icon';
import Modal from 'react-bootstrap/Modal'

import Button from '../components/Button';
import PriceGroup from '../components/PriceGroup';
import Icomoon from '../components/Icomoon';

export default class OtaModal extends Component {

  constructor() {
    super()
    this.state = {
      fastestSelected: false,
      bestPriceSelected: false,
      alternativeSelected: false,
      OtaPriority: 'fastest',
    }
  }

  componentWillReceiveProps(){

    if(this.props.priority ){
      this.setState({OtaPriority: this.props.priority});

      if(this.state.OtaPriority === 'fastest'){
        this.setState({fastestSelected: true});
        this.setState({bestPriceSelected: false});
        this.setState({alternativeSelected: false});

      }else if(this.state.OtaPriority === 'price'){
        this.setState({fastestSelected: false});
        this.setState({bestPriceSelected: true});
        this.setState({alternativeSelected: false});

      }else if(this.state.OtaPriority === 'manual'){
        this.setState({fastestSelected: false});
        this.setState({bestPriceSelected: false});
        this.setState({alternativeSelected: true});
      }
    }

    return true;
  }

  handleSelectedSolution = (name) => (e) => {
    e.preventDefault()

    this.setState({
      fastestSelected: false,
      bestPriceSelected: false,
      alternativeSelected: false,
      [name]: true
    })

    if(name === 'fastestSelected'){
      this.setState({OtaPriority: 'fastest'});
    }else if(name === 'bestPriceSelected'){
      this.setState({OtaPriority: 'price'});
    }else if(name === 'alternativeSelected'){
      this.setState({OtaPriority: 'manual'});
    }
  }

  getImageOTA(company){
    if(company === 'AIR FRANCE'){
      return require('../img/airfrance-logo.png');

    }else if(company === 'VUELING'){
      return require('../img/vueling-logo.png');

    }else if(company === 'IBERIA'){
      return require('../img/iberia-logo.png');

    }else if(company === 'TRANSAVIA'){
      return require('../img/transavia-logo.png');

    }else if(company === 'EASYJET'){
      return require('../img/easy_jet-logo.png');

    }else {
      return null;
    }
  }

  formatDate( date ){

    var dateSearch = this.props.dateSearch;
    var date_end = '';

    if(date === '{T1}'){
      date_end =  new Date(dateSearch.getTime() + (60 * 60 * 24 * 1000));
    }else if( date === '{T2}'){
      date_end =  new Date(dateSearch.getTime() + (60 * 60 * 24 * 1000) + (60 * 60 * 24 * 1000));
    }else{
      date_end = dateSearch;
    }

    const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    return ("0" + date_end.getDate()).slice(-2) + "-" + monthNames[date_end.getMonth()] + '-'+ date_end.getFullYear().toString().substr(-2) ;
  }

  render() {
    const tableFastest = []
    const tablePrice = []
    const tableAltn = []

    if(this.props.dataALTN.length !== 0){
      for (const [index, value] of this.props.dataALTN.FASTEST.entries() ) {

        tableFastest.push(
          <tr key={index} className={'strong'}>

          <td>
              <div className={'aircraft-companies'}>
                <div className={'aircraft-logo'}><img src={this.getImageOTA(value.COMPANY[0])} alt="" /></div>
                { value.COMPANY[1] ?
                  <>
                  <span className={'plus'}>+</span>
                  <div className={'aircraft-logo'}><img src={this.getImageOTA(value.COMPANY[1])} alt="" /></div>
                  </>
                  : '' }
              </div>
          </td>

            <td className={'text-center'}>
              <div className={'flight-travel'}>
                <div className={'from'}>
                  <time className={'date'}>{this.formatDate(value.DATE)} <strong className={'time'}>{value.ETA}</strong></time>
                  <span className={'city'}>{value.ORIGIN}</span>
                </div>

                <div className={'infography'}>
                  <span className={'flight-time'}>{value.FLIGHT_TIME ? value.FLIGHT_TIME : ''}</span>
                  <div className={'chart'}>
                    {value.STOP ?
                      <span className={'stop'}></span>
                    : ''}
                    <span className="line"></span>
                    <Icomoon iconName={'jet'} />
                  </div>
                  {value.STOP ?
                    <span className={'flight-stops'}>
                    <span className={'text-orange'}>1 STOP</span> - {value.STOP}
                    </span>
                    : ''}
                </div>

                <div className={'to'}>
                  <time className={'date'}>{this.formatDate(value.DATE)} <strong className={'time'}>{value.ATA}</strong></time>
                  <span className={'city'}>{value.DESTINATION}</span>
                </div>
              </div>
            </td>
            <td className={'text-center'}>
              <PriceGroup price={value.PRICE_PAX} selected={this.state.fastestSelected} onClick={this.handleSelectedSolution('fastestSelected')} />
            </td>
          </tr>
        );
      }

      for (const [index, value] of this.props.dataALTN.PRICE.entries() ) {

        tablePrice.push(
          <tr key={index} className={'strong'}>

          <td>
              <div className={'aircraft-companies'}>
                <div className={'aircraft-logo'}><img src={this.getImageOTA(value.COMPANY[0])} alt="" /></div>
                { value.COMPANY[1] ?
                  <>
                  <span className={'plus'}>+</span>
                  <div className={'aircraft-logo'}><img src={this.getImageOTA(value.COMPANY[1])} alt="" /></div>
                  </>
                  : '' }
              </div>
          </td>

            <td>
              <div className={'flight-travel'}>
                <div className={'from'}>
                <time className={'date'}>{this.formatDate(value.DATE)} <strong className={'time'}>{value.ETA}</strong></time>
                <span className={'city'}>{value.ORIGIN}</span>
                </div>
                <div className={'infography'}>
                  <span className={'flight-time'}>{value.FLIGHT_TIME ? value.FLIGHT_TIME : ''}</span>
                  <div className={'chart'}>
                    {value.STOP ?
                      <span className={'stop'}></span>
                    : ''}
                    <span className="line"></span>
                    <Icomoon iconName={'jet'} />
                  </div>
                  {value.STOP ?
                    <span className={'flight-stops'}>
                    <span className={'text-orange'}>1 STOP</span> - {value.STOP}
                    </span>
                    : ''}
                </div>
                <div className={'to'}>
                <time className={'date'}>{this.formatDate(value.DATE)} <strong className={'time'}>{value.ATA}</strong></time>
                <span className={'city'}>{value.DESTINATION}</span>
                </div>
              </div>
            </td>
            <td className={'text-center'}>
              <PriceGroup price={value.PRICE_PAX} selected={this.state.bestPriceSelected} onClick={this.handleSelectedSolution('bestPriceSelected')} />
            </td>
          </tr>
        );
      }

      for (const [index, value] of this.props.dataALTN.ALTN.entries() ) {

        tableAltn.push(
          <tr key={index}>
            <td>
                <div className={'aircraft-companies'}>
                  <div className={'aircraft-logo'}><img src={this.getImageOTA(value.COMPANY[0])} alt="" /></div>
                  { value.COMPANY[1] ?
                    <>
                    <span className={'plus'}>+</span>
                    <div className={'aircraft-logo'}><img src={this.getImageOTA(value.COMPANY[1])} alt="" /></div>
                    </>
                    : '' }
                </div>
            </td>

            <td>
              <div className={'flight-travel'}>
                <div className={'from'}>
                <time className={'date'}>{this.formatDate(value.DATE)} <strong className={'time'}>{value.ETA}</strong></time>
                <span className={'city'}>{value.ORIGIN}</span>
                </div>

                <div className={'infography'}>
                  <span className={'flight-time'}>{value.FLIGHT_TIME ? value.FLIGHT_TIME : ''}</span>
                  <div className={'chart'}>

                    {value.STOP ?
                      <span className={'stop'}></span>
                    : ''}

                    <span className="line"></span>
                    <Icomoon iconName={'jet'} />
                  </div>
                  {value.STOP ?
                    <span className={'flight-stops'}>
                    <span className={'text-orange'}>1 STOP</span> - {value.STOP}
                    </span>
                    : ''}
                </div>

                <div className={'to'}>
                <time className={'date'}>{this.formatDate(value.DATE)} <strong className={'time'}>{value.ATA}</strong></time>
                  <span className={'city'}>{value.DESTINATION}</span>
                </div>
              </div>
            </td>
            <td className={'text-center'}>
              {value.SELECTED ? (
                <PriceGroup price={value.PRICE_PAX} selected={this.state.alternativeSelected} onClick={this.handleSelectedSolution('alternativeSelected')} />
              ) : (
                <PriceGroup price={value.PRICE_PAX} />
              )}
            </td>
          </tr>
        );
      }
  }

    return(
      <Modal
        show={this.props.show}
        onHide={this.props.onHide}
        dialogClassName="ota-modal"
        size={'xl'}
      >
        <Modal.Header closeButton>
          <div className={'modal-solution-header'}>
            <div className={'flex-row'}>
              <Icomoon iconName={'store'} />
              <Modal.Title className="tit"><strong>OTA</strong> - Solutions</Modal.Title>
            </div>
            <Button
              text={'Apply selected solution'}
              color={'orange'}
              iconName={'check'}
              onClick={ () => {
                this.props.onHide();
                this.props.changeData('Ota', (this.state.OtaPriority === 'price' ? 'SPECIAL_PRICE' : this.state.OtaPriority )  )
              }}
              />
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className={'table-responsive'}>
            <table className={'table'}>
              <thead>
                <tr>
                  <th>Company</th>
                  <th className={'text-center'}>Flight</th>
                  <th className={'text-center'}>Price/PAX</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td colSpan={3} className={'title-td bg-primary'}>
                    <span className="flex-row">
                      <Icon>access_time</Icon>
                      <span className={'tit'}>Fastest Solution</span>
                    </span>
                  </td>
                </tr>
                {tableFastest}

                <tr>
                  <td colSpan={3} className={'title-td bg-green'}>
                    <span className="flex-row">
                      <Icon>money</Icon>
                      <span className={'tit'}>Best Price Solution</span>
                    </span>
                  </td>
                </tr>
                {tablePrice}

                <tr>
                  <td colSpan={3} className={'title-td bg-yellow'} style={{ paddingTop: 5, paddingBottom: 5 }}>
                    <span className="flex-row">
                      <Icon fontSize={'small'}>check_circle_outline</Icon>
                      <span className={'tit'}>Alternative Solutions</span>
                    </span>
                  </td>
                </tr>
                {tableAltn}

              </tbody>
            </table>
          </div>
        </Modal.Body>
      </Modal>
    )
  }
}
