import React, { Component } from 'react';
import Icomoon from '../components/Icomoon';

export default class TimelineFastest extends Component {

  render() {
    return(
      <div className="box timeline-box">
        <div className="actions-row">
          <p className={'start-action'}>Now<br />{this.props.day}/{this.props.month}</p>
          <div className={'action-step'} style={{ width: '40%' }}>
            <Icomoon iconName={'hourglass'} />
            <p>3h 20min</p>
          </div>
          <div className={'action-group'} style={{ width: '60%' }}>
            <Icomoon iconName={'flight_departure'} />
            <p>4h 15min</p>
          </div>
          <p className={'end-action'}>Solved<br />{this.props.finishdate}</p>
        </div>
        <div className="timeline">
          <span className="timeline-circle bg-orange"></span>
          <span className="timeline-line"></span>
          <span className="timeline-circle bg-green"></span>
          <span className="step" style={{ left: '39.5%' }}></span>
        </div>
        <div className="times-row">
          <p className={'start-time'}>{this.props.startdate}</p>
          {/*
            <p className={'step-time'} style={{ left: '38.4%' }}>18:40</p>
          */}
          <p className={'end-time'}>{this.props.finishhours}</p>
        </div>
      </div>
    )
  }
}
