import React, { Component } from 'react';
import Select from 'react-select';

export default class SingleSelect extends Component {
  render() {
    const { disabled, options, placeholder , name , onChange, onClick, value } = this.props;
    return <Select
      className={'single-select'}
      classNamePrefix={'single-select'}
      isDisabled={disabled}
      name={name}
      value={value}
      onClick={onClick}
      onChange={onChange}
      options={options}
      placeholder={placeholder}
    />;
  }
}

SingleSelect.defaultProps = {
  placeholder: 'Select',
  isDisabled: false
}
