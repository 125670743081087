import React, { Component } from 'react';
import Button from '../components/Button';

export default class PriceGroup extends Component {
  render() {
    const { price, selected, onClick } = this.props;
    return(
      <div className={'price-group'}>
        <span className={'price' + (selected ? ' text-primary' : '')}>{ price }€</span>
        { selected
          ? <Button onClick={ onClick } text={'Selected'} />
          :<Button onClick={ onClick } color={'black-outline'} text={'Select'} />
        }
      </div>
    )
  }
}
