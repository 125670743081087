import React, { Component } from 'react';

import Icomoon from '../components/Icomoon';
import InfoTooltip from '../components/InfoTooltip';
import StyledSwitch from '../components/StyledSwitch';

export default class BoxHeader extends Component {
  render() {

    const { iconName, title, tooltipDisabled, switchInput, checked, onChange, infoText } = this.props;

    return(
      <header className={'box-header'}>
        <div className={'box-title-row'}>
          <Icomoon iconName={iconName} />
          <h2 className={'title-sm'}>{title}</h2>
          <InfoTooltip
            disabled={tooltipDisabled}
            title={infoText}
          />
        </div>
        {( switchInput === true
          ? <StyledSwitch
              checked={checked}
              onChange={onChange}
            />
          : ''
        )}
      </header>
    )
  }
}

BoxHeader.defaultProps = {
  switchInput: false,
  tooltipDisabled: false
}
