import React, { Component } from 'react';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

export default class FormCheckbox extends Component {
  render() {

    const { disabled, id, onChange, required, value, label } = this.props;

    return(
      <FormControlLabel
        className={'form-checkbox boxed-item'}
        control={
          <Checkbox
            color={'default'}
            disabled={disabled}
            disableRipple={true}
            id={id}
            required={required}
            size={'small'}
            value={value}
            onChange={onChange}
          />
        }
        label={
          <div className={'check-label'}>
            <span className={'main-text'}>{label}</span>
          </div>
        }
      />
    )
  }
}
