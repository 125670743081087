import React, { Component } from 'react';

import Collapse from 'react-bootstrap/Collapse';
import Icon from '@material-ui/core/Icon';
import RadioGroup from '@material-ui/core/RadioGroup';

import FormControl from '../components/FormControl';
import FormLabel from '../components/FormLabel';
import FormRadio from '../components/FormRadio';
import LabelGroup from '../components/LabelGroup';
import SingleSelect from '../components/SingleSelect';

export default class AcmiForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      filtersOpen: false
    }
  }

  handleFiltersCollapse = e => {
    this.setState({ filtersOpen: !this.state.filtersOpen });
    e.preventDefault();
  };

  render() {

      const departureSelectOptions = []
      const arrivalSelectOptions = []
      const preferredModelSelectOptions = []
      var i = 0;
      if (typeof this.props.dataFromParent.Airports != "undefined"){
        for (i = 0; i < this.props.dataFromParent.Airports.length; i++) {
            departureSelectOptions.push( {value:'dpt'+i, label: this.props.dataFromParent.Airports[i]} )
        }
        for (i = 0; i < this.props.dataFromParent.Airports.length; i++) {
            arrivalSelectOptions.push( {value:'arv'+i, label: this.props.dataFromParent.Airports[i]} )
        }
        for (i = 0; i < this.props.dataFromParent.PreferredModel.length; i++) {
            preferredModelSelectOptions.push( {value:'pfm'+i, label: this.props.dataFromParent.PreferredModel[i]} )
        }
      }

    return(
      <div className="box-body">
        <div className="form-group">
          <LabelGroup
            iconName={'flight_departure'}
            text={'Departure'}
          />
          <SingleSelect
            disabled={!this.props.boxSelected}
            options={departureSelectOptions}
          />
        </div>
        <div className="form-group">
          <LabelGroup
            iconName={'flight_arrival'}
            text={'Arrival'}
          />
          <SingleSelect
            disabled={!this.props.boxSelected}
            options={arrivalSelectOptions}
          />
        </div>
        <div className={'form-row'}>
          <div className="form-group percent-40">
            <LabelGroup
              iconName={'person_standing'}
              text={'PAX'}
            />
            <FormControl
              disabled={!this.props.boxSelected}
              type={'number'}
            />
          </div>
        </div>

        <Collapse in={this.state.filtersOpen}>
          <div className="acmi-filters">
            <div className={'form-row'}>
              <div className="form-group percent-40">
                <FormLabel title={'Min YOM'} />
                <FormControl
                  disabled={!this.props.boxSelected}
                  type={'number'}
                />
              </div>
              <div className="form-group percent-60">
                <FormLabel title={'IOSA'} />
                <RadioGroup
                  className={'boxed-item'}
                  disabled={!this.props.boxSelected}
                  name={'iosa'}
                >
                  <FormRadio value={'yes'} label={'Yes'} />
                  <FormRadio value={'no'} label={'No'} />
                </RadioGroup>
              </div>
            </div>
            <div className="form-group">
              <FormLabel title={'Preferred model'} />
              <SingleSelect
                disabled={!this.props.boxSelected}
                options={preferredModelSelectOptions}
              />
            </div>
          </div>
        </Collapse>

        <button
          className={'btn-toggle-collapse'}
          onClick={this.handleFiltersCollapse}
          aria-expanded={this.state.filtersOpen}
        >
            <span className="text">
              {this.state.filtersOpen ? 'Close filters' : 'More filters' }
            </span>
          <Icon>{this.state.filtersOpen ? 'highlight_off' : 'control_point' }</Icon>
        </button>

      </div>
    )
  }
}
