import React, { Component } from 'react';

import FormControl from '../components/FormControl';
import SquareIconButton from './SquareIconButton';
import LabelGroup from '../components/LabelGroup';
import SingleSelect from '../components/SingleSelect';
import TabsLine from '../components/TabsLine';
import ResultRow from '../components/ResultRow';

export default class LogisticsForm extends Component {
  constructor(props) {
    super(props)

    this.displayListData = [];

    this.state = {
      showListData: this.displayListData,
      kgListdata: [],
      mListdata: [],

      listIndex: 0,

      logisticstotalkg: 0,
      logisticstotalm: 0,

      logisticskg: '',
      logistcism:'',
    }

    this.appendData = this.appendData.bind(this);
  }

  handleValue = (boxName) => (e) => {
    this.setState({
      [boxName]: e.target.value
    })
  }

  deleteLogisticsRow = (id) => (e) => {
    e.preventDefault();
    var value_kg = this.state.kgListdata[id];
    var value_m = this.state.mListdata[id];

    var showlist = this.state.showListData;
    showlist[id] = []

    this.setState({
      showListData: showlist,
      logisticstotalm: this.state.logisticstotalm - parseFloat(value_m),
      logisticstotalkg: this.state.logisticstotalkg - parseFloat(value_kg)

    });
  }

  appendData(e) {
    e.preventDefault();

    if( this.state.logisticsm){
      this.setState({
        logisticstotalm: this.state.logisticstotalm + parseFloat(this.state.logisticsm)
      })
    }
    this.state.mListdata.push( parseFloat(this.state.logisticsm) );


    if( this.state.logisticskg){
      this.setState({
        logisticstotalkg: this.state.logisticstotalkg + parseFloat(this.state.logisticskg)
      })
    }
    this.state.kgListdata.push( parseFloat(this.state.logisticskg) );

    this.displayListData.push(
      <ResultRow
        onClick={this.deleteLogisticsRow((this.state.listIndex))}
        key={this.state.listIndex}
        description={this.state.logisticskg + ' kg'}
        term={this.state.logisticsm + ' m3'}
        removable
      />
    );
    this.setState({
      showListData : this.displayListData,
      listIndex: this.state.listIndex + 1
    });
  }

  render() {

    const fromSelectOptions = []
    const toSelectOptions = []
    var i = 0;
    if (typeof this.props.dataFromParent.Airports != "undefined"){
        for (i = 0; i < this.props.dataFromParent.Airports.length; i++) {
            fromSelectOptions.push( {value:'dpt'+i, label: this.props.dataFromParent.Airports[i]} )
        }
        for (i = 0; i < this.props.dataFromParent.Airports.length; i++) {
            toSelectOptions.push( {value:'arv'+i, label: this.props.dataFromParent.Airports[i]} )
        }
    }

    return(
      <>
        <TabsLine options={[
          {
            text: 'One-way',
            active: true,
            disabled: !this.props.boxSelected
          },
          {
            text: 'Round Trip',
            active: false,
            disabled: !this.props.boxSelected
          },
          {
            text: 'Multi-leg',
            active: false,
            disabled: !this.props.boxSelected
          }
        ]} />

        <div className="box-body">
          <div className="form-group">
            <LabelGroup
              iconName={'flight_departure'}
              text={'From'}
            />
            <SingleSelect
              disabled={!this.props.boxSelected}
              options={fromSelectOptions}
            />
          </div>
          <div className="form-group">
            <LabelGroup
              iconName={'flight_arrival'}
              text={'To'}
            />
            <SingleSelect
              disabled={!this.props.boxSelected}
              options={toSelectOptions}
            />
          </div>
          <div className={'form-row'}>
            <div className="form-group percent-40">
              <LabelGroup
                iconName={'person_standing'}
                text={'PAX'}
              />
              <FormControl
                disabled={!this.props.boxSelected}
                type={'number'}
              />
            </div>
          </div>


          <div>
            <LabelGroup
              iconName={'case'}
              text={'Freight'}
            />
            <div className={'form-row add-row'}>
              <div className={'form-group percent-40'}>
                <FormControl
                  disabled={!this.props.boxSelected}
                  placeholder={'M3'}
                  name={'logisticsm'}
                  type={'number'}
                  onChange={this.handleValue('logisticsm')}
                />
              </div>
              <div className={'form-group percent-40'}>
                <FormControl
                  disabled={!this.props.boxSelected}
                  placeholder={'Kg'}
                  name={'logisticskg'}
                  type={'number'}
                  onChange={this.handleValue('logisticskg')}
                />
              </div>
              <SquareIconButton
                color={'#'}
                iconName={'add'}
                disabled={!this.props.boxSelected}
                onClick={this.appendData}
              />
            </div>


            <div className="results-list">
              {this.displayListData}
            </div>
            <h2 className="total-title">Total</h2>
            <ResultRow
              term={this.state.logisticstotalm.toFixed(2) === '-0.00' ? '0.00 m3' : this.state.logisticstotalm.toFixed(2) + ' m3'}
              description={this.state.logisticstotalkg.toFixed(2) === '-0.00' ? '0.00 kg' : this.state.logisticstotalkg.toFixed(2) + ' kg'}
            />
          </div>
        </div>

      </>
    )
  }
}
