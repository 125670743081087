import React, { Component } from 'react';
import Icomoon from '../components/Icomoon';

export default class TimelinePrice extends Component {

  render() {
    return(
      <div className={'box timeline-box'}>
        <div>
          <div className="actions-row">
            <p className={'start-action'}>Now<br />{this.props.day}/{this.props.month}</p>
            <div className={'action-step'} style={{ width: '6%' }}>
              <Icomoon iconName={'bus'} />
              <p>1h</p>
            </div>
            <div className={'action-group'} style={{ width: '76%' }}>
              <Icomoon iconName={'hotel_bed'} />
              <p>17h</p>
            </div>
            <div className={'action-group'} style={{ width: '6%' }}>
              <Icomoon iconName={'bus'} />
              <p>1h</p>
            </div>
            <div className={'action-group'} style={{ width: '12%' }}>
              <Icomoon iconName={'flight_departure'} />
              <p>1h 30min</p>
            </div>
            <p className={'end-action'}>Solved<br />{this.props.finishdate}</p>
          </div>
          <div className="timeline">
            <span className="timeline-circle bg-orange"></span>
            <span className="timeline-line"></span>
            <span className="timeline-circle bg-green"></span>
            <span className="step" style={{ left: '9.2%' }}></span>
            <span className="step" style={{ left: '78.5%' }}></span>
            <span className="step" style={{ left: '84%' }}></span>
          </div>
          <div className="times-row">
            <p className={'start-time'}>{this.props.startdate}</p>
            {/*
            <p className={'step-time'} style={{ left: '8.2%' }}>4:55</p>
            <p className={'step-time'} style={{ left: '77.5%' }}>21:55</p>
            <p className={'step-time'} style={{ left: '83%' }}>22:55</p>
            */}
            <p className={'end-time'}>{this.props.finishhours}</p>
          </div>
        </div>

        <div style={{width: '40%'}}>
          <div className="actions-row">
            {/*
            <p className={'start-action'}>Now<br />{this.props.day}/{this.props.month}</p>
            */}
            <div className={'action-step'} style={{ width: '75%' }}>
              <Icomoon iconName={'hourglass'} />
              <p>6h</p>
            </div>
            <div className={'action-group'} style={{ width: '25%' }}>
              <Icomoon iconName={'flight_departure'} />
              <p>2h</p>
            </div>
            <p className={'end-action'}>172 PAX<br />solved</p>
          </div>
          <div className="timeline">
            <span className="timeline-circle bg-orange"></span>
            <span className="timeline-line"></span>
            <span className="timeline-circle bg-green"></span>
            <span className="step" style={{ left: '68%' }}></span>
          </div>
          <div className="times-row">
            <p className={'start-time'}>{this.props.startdate}</p>
            {/*
            <p className={'step-time'} style={{ left: '65.8%' }}>9:55</p>
            */}
            <p className={'end-time'}>{this.props.acmibestprice}</p>
          </div>
        </div>
      </div>
    )
  }
}
