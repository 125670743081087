import React, { Component } from 'react';

import LabelGroup from '../components/LabelGroup';

export default class BusBoxResume extends Component {
  render() {
    return(
      <div className={'box-body'} style={{ height: this.props.bodyHeight }}>
        <div className="form-group">
          <LabelGroup
            iconName={'person_standing'}
            text={'PAX'}
          />
          <p>180</p>
        </div>
        <div className="form-group">
          <LabelGroup
            iconName={'location_airport_departure'}
            text={'From Airport'}
          />
          <p>NTE - Nantes</p>
        </div>
        <div className="form-group">
          <LabelGroup
            iconName={'pin_outline'}
            text={'To'}
          />
          <p>Selected hotel</p>
        </div>
      </div>
    )
  }
}
