import React, { Component } from 'react';

import Icon from '@material-ui/core/Icon';
import Modal from 'react-bootstrap/Modal'

import Button from '../components/Button';
import PriceGroup from '../components/PriceGroup';
import Icomoon from '../components/Icomoon';

export default class AcmiModal extends Component {
  constructor() {
    super()
    this.state = {
      fastestSelected: false,
      bestPriceSelected: false,
      alternativeSelected: false,
      AcmiPriority: 'fastest',
    }
  }

componentWillReceiveProps(){

  if(this.props.priority ){
    this.setState({AcmiPriority: this.props.priority});

    if(this.state.AcmiPriority === 'fastest'){
      this.setState({fastestSelected: true});
      this.setState({bestPriceSelected: false});
      this.setState({alternativeSelected: false});

    }else if(this.state.AcmiPriority === 'price'){
      this.setState({fastestSelected: false});
      this.setState({bestPriceSelected: true});
      this.setState({alternativeSelected: false});

    }else if(this.state.AcmiPriority === 'manual'){
      this.setState({fastestSelected: false});
      this.setState({bestPriceSelected: false});
      this.setState({alternativeSelected: true});
    }
  }
  return true;
}

  handleSelectedSolution = (name) => (e) => {

    e.preventDefault()
    this.setState({
      fastestSelected: false,
      bestPriceSelected: false,
      alternativeSelected: false,
      [name]: true
    })

    if(name === 'fastestSelected'){
      this.setState({AcmiPriority: 'fastest'});
    }else if(name === 'bestPriceSelected'){
      this.setState({AcmiPriority: 'price'});
    }else if(name === 'alternativeSelected'){
      this.setState({AcmiPriority: 'manual'});
    }
  }

  render() {
    const tableFastest = []
    const tablePrice = []
    const tableAltn = []

    if(this.props.dataALTN.length !== 0){

      for (const [index, value] of this.props.dataALTN.FASTEST.entries() ) {
        tableFastest.push(
          <tr key={'altn-'+index} className={'strong'}>
            <td>{value.AIRCRAFT}</td>
            <td>{value.COMPANY}</td>
            <td>{value.HOMEBASE}</td>
            <td>{value.PAX}</td>
            <td>{value.IOSA}</td>
            <td>{value.YOM_AVG}</td>
            <td>{value.TIME_TO_ACTION}</td>
            <td>{value.PBH_RATE} €</td>
            <td>{value.BH}</td>
            <td>
              <PriceGroup price={value.PRICE} selected={this.state.fastestSelected} onClick={this.handleSelectedSolution('fastestSelected')} />
            </td>
          </tr>);
      }

      for (const [index, value] of this.props.dataALTN.PRICE.entries() ) {
        tablePrice.push(
          <tr key={'fastest-'+index} className={'strong'}>
            <td>{value.AIRCRAFT}</td>
            <td>{value.COMPANY}</td>
            <td>{value.HOMEBASE}</td>
            <td>{value.PAX}</td>
            <td>{value.IOSA}</td>
            <td>{value.YOM_AVG}</td>
            <td>{value.TIME_TO_ACTION}</td>
            <td>{value.PBH_RATE} €</td>
            <td>{value.BH}</td>
            <td>
              <PriceGroup price={value.PRICE} selected={this.state.bestPriceSelected} onClick={this.handleSelectedSolution('bestPriceSelected')} />
            </td>
          </tr>);
      }

      for (const [index, value] of this.props.dataALTN.ALTN.entries() ) {
        tableAltn.push(
          <tr key={'altn-'+index}>
           <td>{value.AIRCRAFT}</td>
           <td>{value.COMPANY}</td>
           <td>{value.HOMEBASE}</td>
           <td>{value.PAX}</td>
           <td>{value.IOSA}</td>
           <td>{value.YOM_AVG}</td>
           <td>{value.TIME_TO_ACTION}</td>
           <td>{value.PBH_RATE} €</td>
           <td>{value.BH}</td>
           <td>
              {value.SELECTED ? (
                <PriceGroup price={value.PRICE} selected={this.state.alternativeSelected} onClick={this.handleSelectedSolution('alternativeSelected')} />
              ) : (
                <PriceGroup price={value.PRICE} />
              )}
           </td>
         </tr>);
      }
    }

    return(
      <Modal
        show={this.props.show}
        onHide={this.props.onHide}
        dialogClassName="acmi-modal"
        size={'xl'}
      >
        <Modal.Header closeButton>
          <div className={'modal-solution-header'}>
            <div className={'flex-row'}>
              <Icomoon iconName={'flight_departure'} />
              <Modal.Title className="tit"><strong>Acmi</strong> - Solutions</Modal.Title>
            </div>
            <Button
              text={'Apply selected solution'}
              color={'orange'}
              iconName={'check'}
              onClick={ () => {
                this.props.onHide();
                this.props.changeData('ACMI', this.state.AcmiPriority )
              }}
            />
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className={'table-responsive'}>
            <table className={'table'}>
              <thead>
                <tr>
                  <th>Aircraft</th>
                  <th>Company</th>
                  <th>Homebase</th>
                  <th>PAX</th>
                  <th>IOSA</th>
                  <th>YOM AVG</th>
                  <th>Time to action</th>
                  <th>PBH Rate</th>
                  <th>Bg</th>
                  <th className={'text-center'}>Price</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td colSpan={10} className={'title-td bg-primary'}>
                    <span className="flex-row">
                      <Icon>access_time</Icon>
                      <span className={'tit'}>Fastest Solution</span>
                    </span>
                  </td>
                </tr>
                {tableFastest}

                <tr>
                  <td colSpan={10} className={'title-td bg-green'}>
                    <span className="flex-row">
                      <Icon>money</Icon>
                      <span className={'tit'}>Best Price Solution</span>
                    </span>
                  </td>
                </tr>
                {tablePrice}

                <tr>
                  <td colSpan={10} className={'title-td bg-yellow'} style={{ paddingTop: 5, paddingBottom: 5 }}>
                    <span className="flex-row">
                      <Icon fontSize={'small'}>check_circle_outline</Icon>
                      <span className={'tit'}>Alternative Solutions</span>
                    </span>
                  </td>
                </tr>
                {tableAltn}

              </tbody>
            </table>
          </div>
        </Modal.Body>
      </Modal>
    )
  }
}
