import React, { Component } from 'react';

import FormControl from '../components/FormControl';
import FormCheckbox from '../components/FormCheckbox';
import LabelGroup from '../components/LabelGroup';
import SingleSelect from '../components/SingleSelect';

export default class OtaForm extends Component {

  render() {

    const departureSelectOptions = []
    const arrivalSelectOptions = []
    var i = 0;
    if (typeof this.props.dataFromParent.Airports != "undefined"){
        for (i = 0; i < this.props.dataFromParent.Airports.length; i++) {
            departureSelectOptions.push( {value:'dpt'+i, label: this.props.dataFromParent.Airports[i]} )
        }
        for (i = 0; i < this.props.dataFromParent.Airports.length; i++) {
            arrivalSelectOptions.push( {value:'arv'+i, label: this.props.dataFromParent.Airports[i]} )
        }
    }

    return(
      <div className="box-body">
        <div className="form-group">
          <LabelGroup
            iconName={'flight_departure'}
            text={'Departure'}
          />
          <SingleSelect
            disabled={!this.props.boxSelected}
            options={departureSelectOptions}
          />
        </div>
        <div className="form-group">
          <LabelGroup
            iconName={'flight_arrival'}
            text={'Arrival'}
          />
          <SingleSelect
            disabled={!this.props.boxSelected}
            options={arrivalSelectOptions}
          />
        </div>
        <div className={'form-row'}>
          <div className="form-group percent-40">
            <LabelGroup
              iconName={'person_standing'}
              text={'PAX'}
            />
            <FormControl
              disabled={!this.props.boxSelected}
              type={'number'}
            />
          </div>
        </div>
        <div className={'form-group'}>
          <FormCheckbox
            disabled={!this.props.boxSelected}
            label={'Nearby airports'}
          />
          <FormCheckbox
            disabled={!this.props.boxSelected}
            label={'Only direct flights'}
          />
        </div>

      </div>
    )
  }
}
