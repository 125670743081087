import React from 'react';

import airEuropaLogo from '../img/air_europa-logo.png';
import airNostrumLogo from '../img/air_nostrum-logo.png';
import albaStarLogo from '../img/alba_star-logo.png';
import privilegeStyleLogo from '../img/privilege_style-logo.png';
import voloteaLogo from '../img/volotea-logo.png';
import vuelingLogo from '../img/vueling-logo.png';

function switchLogos(client) {
  switch (client) {
    case 'aireuropa':
      return <img className={'logo'} src={airEuropaLogo} alt={'Air Europa'}/>

    case 'airnostrum':
      return <img className={'logo'} src={airNostrumLogo} alt={'Air Nostrum'}/>

    case 'albastar':
      return <img className={'logo'} src={albaStarLogo} alt={'Alba Star'}/>

    case 'privilegestyle':
      return <img className={'logo'} src={privilegeStyleLogo} alt={'Privilege Style'}/>

    case 'volotea':
      return <img className={'logo'} src={voloteaLogo} alt={'Volotea'}/>

    case 'vueling':
      return <img className={'logo'} src={vuelingLogo} alt={'Vueling'}/>

    default:
      return null;

  }
}

export default function Footer(props) {
  return(
    <footer className="footer">
      <div className="container">
        <figure className={'logo-group'}>
          <figcaption className={'caption'}>OUR DATA PARTNER</figcaption>
          <img className={'logo'} src={require('../img/cirium-logo.png')} alt="Cirium"/>
        </figure>

        { switchLogos(props.clientLogo) &&
          <figure className={'logo-group'}>
            <figcaption className={'caption'}>DESIGNED FOR</figcaption>
            { switchLogos(props.clientLogo) }
          </figure>
         }


      </div>
    </footer>
  )
}
