import React, { Component } from 'react';

export default class FormLabel extends Component {
  render() {
    return <label
      className={'form-label'}
      style={this.props.style}
    >
      { this.props.title }
    </label>
  }
}
