import React, { Component } from 'react';

import Link from 'react-router-dom/Link';
import Icon from '@material-ui/core/Icon';

import Icomoon from '../components/Icomoon';
import LinkButton from '../components/LinkButton';
import LabelGroup from '../components/LabelGroup';
import FormLabel from '../components/FormLabel';
import SolutionTypeTitle from '../components/SolutionTypeTitle';
import ResultRow from '../components/ResultRow';
import TimelineFastest from '../components/TimelineFastest';
import TimelineManual from '../components/TimelineManual';
import TimelinePrice from '../components/TimelinePrice';

export default class PaymentScreen extends Component {

  constructor() {
    super()

    this.state = {
      priority: 'price', /* fastest, price o manual */
      dataCheckout: [],
      selectedDay: '',
      selectedMonth: '',
      selectedStartHours: '',
      dataBack: [],
      selectedFinishDate: '',
      selectedFinishHours: '',
    }
  }

getStars(number){

  let table = []
   for (let i = 0; i < parseInt(number); i++) {
     table.push(<Icon key={i}>star</Icon>);
   }
   return table
}

componentDidMount() {

    window.scrollTo(0, 0)

    if(typeof this.props.location.state !== 'undefined' && typeof this.props.location.state['priority'] !== 'undefined' ){
      var priority_loc = this.props.location.state.priority;
      this.setState({ priority: priority_loc })
    }

    if(typeof this.props.location.state !== 'undefined' && typeof this.props.location.state['datasback'] !== 'undefined' ){
      var datasback = this.props.location.state.datasback;
      this.setState({ dataBack: datasback })
    }

    var date = '';
    var month = '';
    var date_complete = '';
    var date_finish_date = '';
    var date_finish_hours = '';

    if(typeof this.props.location.state !== 'undefined' && typeof this.props.location.state['datas'] !== 'undefined' ){

      var datas = this.props.location.state.datas;
      this.setState({ dataCheckout: datas }, function(){

        //START TIME
        var date_complete = this.state.dataCheckout['TOTAL']['DATA_SEARCH_COMPLETE'];

        var dat = new Date( date_complete);

        var date = ("0" + dat.getDate()).slice(-2)   ;
        var month =  ("0"+ (dat.getMonth() + 1) ).slice(-2)
        var start_date = ("0" + dat.getHours()).slice(-2) + ":" + ("0" + dat.getMinutes()).slice(-2)

        //END TIME
        var date_complete_end = this.state.dataCheckout['TOTAL']['LEAD'];
        date_complete_end = date_complete_end.split( " " );

        date_finish_date = date_complete_end[0];
        date_finish_hours = date_complete_end[1];
        this.setState({
          selectedDay: date,
          selectedMonth: month,
          selectedStartHours: start_date,
          selectedFinishDate: date_finish_date,
          selectedFinishHours: date_finish_hours,
        })

      });

    }

    this.setState({
      selectedDay: date,
      selectedMonth: month,
      selectedStartHours: date_complete,
      selectedFinishDate: date_finish_date,
      selectedFinishHours: date_finish_hours,
    })

}

getImageOTA(company){
  if(company === 'AIR FRANCE'){
    return require('../img/airfrance-logo.png');

  }else if(company === 'VUELING'){
    return require('../img/vueling-logo.png');

  }else if(company === 'IBERIA'){
    return require('../img/iberia-logo.png');

  }else if(company === 'TRANSAVIA'){
    return require('../img/transavia-logo.png');

  }else if(company === 'EASYJET'){
    return require('../img/easy_jet-logo.png');

  }else {
    return null;
  }
}

getTypeColor(type){
  if(type === 'MANUAL'){
    return 'alternative';
  }else{
    return type.toLowerCase();
  }
}

formatDate( date ){

  var dateSearch = this.state.dataCheckout['TOTAL']['DATA_SEARCH_COMPLETE'];

  var date_end = '';

  if(date === '{T1}'){
    date_end =  new Date(dateSearch.getTime() + (60 * 60 * 24 * 1000));
  }else if( date === '{T2}'){
    date_end =  new Date(dateSearch.getTime() + (60 * 60 * 24 * 1000) + (60 * 60 * 24 * 1000));
  }else{
    date_end = dateSearch;
  }

  return ("0" + date_end.getDate()).slice(-2) + "/" + ("0"+(date_end.getMonth()+1)).slice(-2);
}

  render() {
    return(
      <main className={'payment-screen'}>
        <div className="container">
          <div className="breadcrumbs">
            <Link to={{ pathname: '/results', state: { priority: this.state.priority , datasback: this.state.dataBack } } } className={'ico-text'}>
              <Icon>chevron_left</Icon>
              <span className="text">Go Back</span>
            </Link>
          </div>
          <h2 className="title-md">Your selected solution</h2>
          {
            this.state.priority === 'fastest'
              ? <TimelineFastest day={this.state.selectedDay} month={this.state.selectedMonth} startdate={this.state.selectedStartHours} finishdate={this.state.selectedFinishDate} finishhours={this.state.selectedFinishHours} />
              : null
          }
          {
            this.state.priority === 'price'
              ? <TimelinePrice day={this.state.selectedDay} month={this.state.selectedMonth} startdate={this.state.selectedStartHours} finishdate={this.state.selectedFinishDate} finishhours={this.state.selectedFinishHours} acmibestprice={this.state.dataCheckout['TOTAL'] ? this.state.dataCheckout['TOTAL']['BEST_PRICE_ACMI'] : ''} />
              : null
          }
          {
            this.state.priority === 'manual'
              ? <TimelineManual day={this.state.selectedDay} month={this.state.selectedMonth} startdate={this.state.selectedStartHours} finishdate={this.state.selectedFinishDate} finishhours={this.state.selectedFinishHours} />
              : null
          }

          <div className={'row'}>
            <div className={'col-8'}>

              {/* ACMI BOX */}
              {this.state.dataCheckout['ACMI'] &&
              <div className={'box acmi-box'}>
                <header className="box-header">
                  <div className={'box-title-row'}>
                    <Icomoon iconName={'flight_departure'} />
                    <h2 className={'title-sm'}>ACMI</h2>
                  </div>
                </header>
                <div className={'box-body'}>
                  <div className="row">
                    <div className="col-3 form-group">
                      <LabelGroup
                        iconName={'flight_departure'}
                        text={'Departure'}
                      />
                      <p>{this.state.dataCheckout['ACMI'] ? this.state.dataCheckout['ACMI']['DEPARTURE'] : ''}</p>
                    </div>
                    <div className="col-3 form-group">
                      <LabelGroup
                        iconName={'flight_arrival'}
                        text={'Arrival'}
                      />
                      <p>{this.state.dataCheckout['ACMI'] ? this.state.dataCheckout['ACMI']['ARRIVAL'] : ''}</p>
                    </div>
                    <div className="col-3 form-group">
                      <LabelGroup
                        iconName={'person_standing'}
                        text={'PAX'}
                      />
                      <p>{this.state.dataCheckout['ACMI'] ? this.state.dataCheckout['ACMI']['PAX'] : ''}</p>
                    </div>
                  </div>
                  <div className={'row'}>
                    <div className={'col-3 form-group'}>
                      <FormLabel title={'Min YOM'} />
                      <p>{this.state.dataCheckout['ACMI'] ? this.state.dataCheckout['ACMI']['MIN_YOM'] : ''}</p>
                    </div>
                    <div className={'col-3 form-group'}>
                      <FormLabel title={'Preferred Model'} />
                      <p>{this.state.dataCheckout['ACMI'] ? this.state.dataCheckout['ACMI']['PREFERRED_MODEL'] : ''}</p>
                    </div>
                    <div className={'col-3 form-group'}>
                      <FormLabel title={'IOSA'} />
                      <p>{this.state.dataCheckout['ACMI'] ? this.state.dataCheckout['ACMI']['IOSA'] : ''}</p>
                    </div>
                  </div>
                </div>

                <SolutionTypeTitle type={ this.state.dataCheckout['ACMI'] ? this.getTypeColor( this.state.dataCheckout['ACMI']['RESULT']['TYPE'] ) : '' } />

                <div className={'table-responsive'}>
                  <table className={'table'}>
                    <thead>
                    <tr>
                      <th>Aircraft</th>
                      <th>Company</th>
                      <th className={'text-center'}>Time to action</th>
                      <th className={'text-center'}>PAX</th>
                      <th className={'text-center'}>IOSA</th>
                      <th className={'text-right'}>Price</th>
                    </tr>
                    </thead>
                    <tbody>

                    { this.state.dataCheckout['ACMI'] ? this.state.dataCheckout['ACMI']['RESULT']['CHECKOUT'].map((value, i) => {
                       return (
                         <tr key={i}>
                           <td>{this.state.dataCheckout['ACMI'] ? value.AIRCRAFT : '' }</td>
                           <td>{this.state.dataCheckout['ACMI'] ? value.COMPANY : '' }</td>
                           <td className={'text-center'}>{this.state.dataCheckout['ACMI'] ? value.TIME_TO_ACTION : '' }</td>
                           <td className={'text-center'}>{this.state.dataCheckout['ACMI'] ? value.PAX : '' }</td>
                           <td className={'text-center'}>{this.state.dataCheckout['ACMI'] ? value.IOSA : '' }</td>
                           <td className={'text-right'}>
                             <span className={'price'} style={{fontWeight: '800'}}>{this.state.dataCheckout['ACMI'] ? value.PRICE : '' }€</span>
                           </td>
                         </tr>
                       )
                    }) : <tr></tr> }

                    </tbody>
                  </table>
                </div>
              </div>
              }

              {/* HOTEL BOX */}
              {this.state.dataCheckout['HOTELS'] &&
              <div className={'box hotel-box'}>
                <header className="box-header">
                  <div className={'box-title-row'}>
                    <Icomoon iconName={'hotel_bed'} />
                    <h2 className={'title-sm'}>HOTEL</h2>
                  </div>
                </header>
                <div className="box-body">
                  <div className={'row'}>
                    <div className={'col-3 form-group'}>
                      <LabelGroup
                        iconName={'city'}
                        text={'Airport'}
                      />
                      <p>{this.state.dataCheckout['HOTELS'] ? this.state.dataCheckout['HOTELS']['AIRPORT'] : ''}</p>
                    </div>
                    <div className={'col-2 form-group'}>
                      <LabelGroup
                        iconName={'location_pin_area'}
                        text={'Km range'}
                      />
                      <p>{this.state.dataCheckout['HOTELS'] ? this.state.dataCheckout['HOTELS']['KM_RANGE'] : ''}</p>
                    </div>
                    <div className={'col-3 form-group'}>
                      <LabelGroup
                        iconName={'star'}
                        text={'Categories'}
                      />
                      <div className={'tags-row'}>

                      { this.state.dataCheckout['HOTELS'] ? this.state.dataCheckout['HOTELS']['CATEGORY'].map((value, i) => {
                         return (
                           <div key={i} className="tag boxed-item">
                             <div className={'stars-row'}>
                             {this.getStars(value)}
                             </div>
                           </div>
                         )
                      }) : null }

                      </div>
                    </div>
                  </div>

                  <div className={'row'}>
                    <div className={'col-3 form-group'}>
                      <LabelGroup
                        iconName={'hotel_bed'}
                        text={'Room type'}
                      />

                      <div className={'results-list'}>
                      { this.state.dataCheckout['HOTELS'] ? this.state.dataCheckout['HOTELS']['ROOMS'].map((value, i) => {
                         return (
                           <ResultRow key={i}
                             term={value[0]}
                             description={value[1]}
                           />
                         )
                      }) : null }
                      </div>

                      <h2 className="total-title">Total</h2>
                      <ResultRow
                        term={ this.state.dataCheckout['HOTELS'] ? this.state.dataCheckout['HOTELS']['TOTAL_ROOMS'][0][0] : ''}
                        description={this.state.dataCheckout['HOTELS'] ? this.state.dataCheckout['HOTELS']['TOTAL_ROOMS'][0][1] : ''}
                      />
                    </div>
                  </div>
                </div>

                <SolutionTypeTitle type={ this.state.dataCheckout['HOTELS'] ? this.getTypeColor( this.state.dataCheckout['HOTELS']['RESULT']['TYPE'] ) : '' }  />

                <table className={'table'}>
                  <thead>
                  <tr>
                    <th>Hotel</th>
                    <th>Category</th>
                    <th className={'text-right'}>PAX</th>
                    <th className={'text-right'}>ROOMS</th>
                    <th className={'text-right'}>PRICE</th>
                  </tr>
                  </thead>
                  <tbody>

                  { this.state.dataCheckout['HOTELS'] ? this.state.dataCheckout['HOTELS']['RESULT']['CHECKOUT']['DATA'].map((value, i) => {
                        return (<React.Fragment key={i}>
                          { value['ROW'].map((value2, i2) => {
                            return (
                            <tr key={i+'-'+i2}>
                              { i2 === 0 ?
                                <>
                                <td rowSpan={value.length} style={{ verticalAlign: 'top', paddingTop: 11, }}>
                                  <span className="table-tit">{value.HOTEL}</span>
                                </td>
                                <td>
                                  <div className="stars-row">{this.getStars( value.CATEGORY )}</div>
                                </td>
                                </>
                              : <><td></td><td></td></> }

                              <td className={'text-right'}>{value2.PAX}</td>
                              <td className={'text-right'}>{value2.ROOMS}</td>

                                <td className={'text-right'}>
                                  <span className="price">{value2.PRICE} €</span>
                                </td>


                            </tr>
                            )
                        })}
                        </React.Fragment>)
                     }) : null }

                     { this.state.dataCheckout['HOTELS'] ?
                     <tr>
                       <td>TOTAL</td>
                       <td></td>
                       <td className={'text-right'}>{this.state.dataCheckout['HOTELS']['RESULT']['CHECKOUT']['TOTAL']['PAX']}</td>
                       <td className={'text-right'}>{this.state.dataCheckout['HOTELS']['RESULT']['CHECKOUT']['TOTAL']['ROOMS']} ROOMS</td>
                       <td colSpan={6} className={'text-right'}>
                         <span className={'price'} style={{ fontWeight: '800' }}>{this.state.dataCheckout['HOTELS']['RESULT']['CHECKOUT']['TOTAL']['PRICE']} €</span>
                       </td>
                     </tr>
                    : null }
                  </tbody>

                </table>
              </div>
              }


              {/* BUS BOX */}
              {this.state.dataCheckout['BUS'] &&
              <div className={'box bus-box'}>
                <header className="box-header">
                  <div className={'box-title-row'}>
                    <Icomoon iconName={'bus'} />
                    <h2 className={'title-sm'}>TRANSPORT</h2>
                  </div>
                </header>
                <div className={'box-body'}>
                  <div className={'row'}>
                    <div className={'col-2 form-group'}>
                      <LabelGroup
                        iconName={'person_standing'}
                        text={'PAX'}
                      />
                      <p>{ this.state.dataCheckout['BUS'] ? this.state.dataCheckout['BUS']['PAX'] : ''}</p>
                    </div>
                    <div className={'col-3 form-group'}>
                      <LabelGroup
                        iconName={'location_airport_departure'}
                        text={'From Airport'}
                      />
                      <p>{ this.state.dataCheckout['BUS'] ? this.state.dataCheckout['BUS']['FROM_AIRPORT'] : ''}</p>
                    </div>
                    <div className={'col-3 form-group'}>
                      <LabelGroup
                        iconName={'pin_outline'}
                        text={'To'}
                      />
                      <p>{ this.state.dataCheckout['BUS'] ? this.state.dataCheckout['BUS']['TO'] : ''}</p>
                    </div>
                  </div>
                </div>

                <SolutionTypeTitle type={ this.state.dataCheckout['BUS'] ? this.getTypeColor( this.state.dataCheckout['BUS']['RESULT']['TYPE'] ) : '' }  />

                <div className={'table-responsive'}>
                  <table className={'table align-cells-top'}>
                    <thead>

                    <tr>
                      <th>Company</th>
                      <th>Hotel Selected</th>
                      <th className={'text-center'}>Km</th>
                      <th className={'text-center'}>PAX</th>
                      <th className={'text-center'}>Transport</th>
                      <th className={'text-center'}>Time to action</th>
                      <th className={'text-right'}>Price</th>
                    </tr>
                    </thead>
                    <tbody>

                    { this.state.dataCheckout['BUS'] ? this.state.dataCheckout['BUS']['RESULT']['CHECKOUT']['DATA'].map((value, i) => {
                          return (
                            <tr key={i}>
                              <td>{value.COMPANY}</td>
                              <td>{value.HOTEL_SELECTED}</td>
                              <td className={'text-center'}>{value.KM}</td>
                              <td className={'text-center'}>{value.PAX}</td>
                              <td className={'text-center'}>{value.TRANSPORT}</td>
                              <td className={'text-center'}>{value.TIME_TO_ACTION}</td>
                              <td className={'text-right'}>
                                <span className="price">{value.PRICE}</span>
                              </td>
                            </tr>

                          )
                       }) : null }

                       { this.state.dataCheckout['BUS'] ?

                       <tr>
                          <td>TOTAL</td>
                          <td></td>
                          <td className={'text-center'}>{this.state.dataCheckout['BUS']['RESULT']['CHECKOUT']['TOTAL']['KM']}</td>
                          <td className={'text-center'}>{this.state.dataCheckout['BUS']['RESULT']['CHECKOUT']['TOTAL']['PAX']}</td>
                          <td className={'text-center'}>{this.state.dataCheckout['BUS']['RESULT']['CHECKOUT']['TOTAL']['TRANSPORT']}</td>
                          <td className={'text-center'}>{this.state.dataCheckout['BUS']['RESULT']['CHECKOUT']['TOTAL']['TIME_TO_ACTION']}</td>
                           <td colSpan={7} className={'text-right'}>
                             <span className={'price'} style={{ fontWeight: '800' }}>{this.state.dataCheckout['BUS']['RESULT']['CHECKOUT']['TOTAL']['PRICE']} €</span>
                           </td>
                       </tr>


                      : null }


                    </tbody>
                  </table>
                </div>
              </div>
              }

              {/* LOGISTICS BOX */}
              {this.state.dataCheckout['LOGISTICS'] &&
              <div className={'box logistics-box'}>
                <header className="box-header">
                  <div className={'box-title-row'}>
                    <Icomoon iconName={'cog_double'} />
                    <h2 className={'title-sm'}>LOGISTICS</h2>
                  </div>
                </header>
                <div className={'box-body'}>
                  <div className={'row'}>
                    <div className={'col-2 form-group'}>
                      <LabelGroup
                        iconName={'person_standing'}
                        text={'PAX'}
                      />
                      <p>{this.state.dataCheckout['LOGISTICS'] ? this.state.dataCheckout['LOGISTICS']['PAX'] : ''}</p>
                    </div>
                    <div className={'col-3 form-group'}>
                      <LabelGroup
                        iconName={'flight_departure'}
                        text={'From'}
                      />
                      <p>{this.state.dataCheckout['LOGISTICS'] ? this.state.dataCheckout['LOGISTICS']['FROM'] : ''}</p>
                    </div>
                    <div className={'col-3 form-group'}>
                      <LabelGroup
                        iconName={'flight_arrival'}
                        text={'To'}
                      />
                      <p>{this.state.dataCheckout['LOGISTICS'] ? this.state.dataCheckout['LOGISTICS']['TO'] : ''}</p>
                    </div>
                  </div>
                  <div className={'row'}>
                    <div className={'col-3 form-group'}>
                      <LabelGroup
                        iconName={'case'}
                        text={'Freight'}
                      />

                      <div className="results-list">
                      { this.state.dataCheckout['LOGISTICS'] ? this.state.dataCheckout['LOGISTICS']['FREIGHT'].map((value, i) => {
                         return (
                           <ResultRow key={i}
                             description={value.split('-')[0]}
                             term={value.split('-')[1]}
                           />
                         )
                      }) : null  }
                      </div>
                    </div>
                  </div>
                </div>

                <SolutionTypeTitle type={ this.state.dataCheckout['LOGISTICS'] ? this.getTypeColor( this.state.dataCheckout['LOGISTICS']['RESULT']['TYPE'] ) : '' }  />

                <div className={'table-responsive'}>
                  <table className={'table'}>
                    <thead>
                    <tr>
                      <th>Aircraft</th>
                      {/*
                      <th>Company</th>
                      */}
                      <th className={'text-center'}>PAX</th>
                      <th className={'text-center'}>Freight</th>
                      <th className={'text-center'}>Time to action</th>
                      <th className={'text-right'}>Price</th>
                    </tr>
                    </thead>
                    <tbody>
                    { this.state.dataCheckout['LOGISTICS'] ? this.state.dataCheckout['LOGISTICS']['RESULT']['CHECKOUT'].map((value, i) => {
                       return (

                         <tr key={i}>
                           <td>{value.AIRCRAFT}</td>
                           {/*
                            <td>{value.COMPANY}</td>
                           */}
                           <td className={'text-center'}>{value.PAX}</td>
                           <td className={'text-center'}>{value.FREIGHT}</td>
                           <td className={'text-center'}>{value.TIME_TO_ACTION}</td>
                           <td className={'text-right'}>
                             <span className="price" style={{ fontWeight: '800' }}>{value.PRICE}€</span>
                           </td>
                         </tr>

                       )
                    }) : null  }
                    {/* <tr>
                      <td colSpan={5} className={'text-right'}>
                        <span className={'price'} style={{ fontWeight: '800' }}>6.400,00 €</span>
                      </td>
                    </tr>*/}
                    </tbody>
                  </table>
                </div>
              </div>
              }

              {/* OTA BOX */}
              {this.state.dataCheckout['OTA'] &&
              <div className={'box ota-box'}>
                <header className="box-header">
                  <div className={'box-title-row'}>
                    <Icomoon iconName={'store'} />
                    <h2 className={'title-sm'}>OTA</h2>
                  </div>
                </header>
                <div className={'box-body'}>
                  <div className={'row'}>
                    <div className="col-3 form-group">
                      <LabelGroup
                        iconName={'flight_departure'}
                        text={'Departure'}
                      />
                      <p>{this.state.dataCheckout['OTA'] ? this.state.dataCheckout['OTA']['DEPARTURE'] : ''}</p>
                    </div>
                    <div className="col-3 form-group">
                      <LabelGroup
                        iconName={'flight_arrival'}
                        text={'Arrival'}
                      />
                      <p>{this.state.dataCheckout['OTA'] ? this.state.dataCheckout['OTA']['ARRIVAL'] : ''}</p>
                    </div>
                    <div className="col-3 form-group">
                      <LabelGroup
                        iconName={'person_standing'}
                        text={'PAX'}
                      />
                      <p>{this.state.dataCheckout['OTA'] ? this.state.dataCheckout['OTA']['PAX'] : ''}</p>
                    </div>
                  </div>
                </div>
                <SolutionTypeTitle type={ this.state.dataCheckout['OTA'] ? this.getTypeColor( this.state.dataCheckout['OTA']['RESULT']['TYPE'] ) : '' }  />
                <div className={'table-responsive'}>
                  <table className={'table'}>
                    <thead>
                    <tr>
                      <th>Company</th>
                      <th className={'text-center'}>Flight</th>
                      <th className={'text-center'}>PAX</th>
                      <th className={'text-right'}>Price</th>
                    </tr>
                    </thead>
                    <tbody>

                    { this.state.dataCheckout['OTA'] ? this.state.dataCheckout['OTA']['RESULT']['CHECKOUT'].map((value, i) => {
                       return (
                         <React.Fragment key={i}>
                         <tr >
                           <td>
                               <div className={'aircraft-companies'}>
                                 <div className={'aircraft-logo'}><img src={this.getImageOTA(value.COMPANY[0])} alt="" /></div>
                                 { value.COMPANY[1] ?
                                   <>
                                   <span className={'plus'}>+</span>
                                   <div className={'aircraft-logo'}><img src={this.getImageOTA(value.COMPANY[1])} alt="" /></div>
                                   </>
                                   : '' }
                               </div>
                           </td>
                           <td>
                             <div className={'flight-travel'}>
                               <div className={'from'}>
                                 <time className={'date'}>{ this.formatDate(value.DATE_BEGIN) } <strong className={'time'}>{value.TIME_BEGIN}</strong></time>
                                 <span className={'city'}>{value.FROM}</span>
                               </div>
                               <div className={'infography'}>
                                 <span className={'flight-time'}>{value.FLIGHT_TIME}</span>
                                 <div className={'chart'}>
                                   <span className="line"></span>
                                   <Icomoon iconName={'jet'} />
                                 </div>
                                 <span className={'flight-stops'}>{value.FLIGHT_STOPS}</span>
                               </div>
                               <div className={'to'}>
                                 <time className={'date'}>{ this.formatDate( value.DATE_END ) } <strong className={'time'}>{value.TIME_END}</strong></time>
                                 <span className={'city'}>{value.TO}</span>
                               </div>
                             </div>
                           </td>
                           <td className={'text-center'}>
                             {value.PAX}
                           </td>
                           <td className={'text-right'}>
                             <span className="price">{value.PRICE} €/PAX</span>
                           </td>
                         </tr>
                         <tr >
                           <td>TOTAL</td>
                           <td colSpan={4} className={'text-right'}>
                             <span className={'price'} style={{ fontWeight: '800' }}>{value.TOTAL} €</span>
                           </td>
                         </tr>
                         </React.Fragment>
                       )
                    }) : null  }
                    </tbody>
                  </table>
                </div>
              </div>
            }
            </div>

            <aside className={'col-4'}>
              {/* CREDIT BOX */}
              <div className={'box credit-box'}>
                <p>Your available credit</p>
                <div className={'credit-area'}>
                  <div className={'text'}>
                    <span className={'price'}>345.000 €</span>
                    <button className={'btn-text'}>ADD CREDIT</button>
                  </div>
                  <Icomoon iconName={'wallet'} />
                </div>
              </div>

              {/* CHECKOUT BOX */}
              <div className={'box checkout-box'}>
                <h4 className={'tit'}>Checkout</h4>
                <div className={'line'}>
                  <div className="label-group">
                    <span
                      className={'material-icons'}
                      style={{ fontSize: 18, position: 'absolute', left: 0, top: 1 }}
                    >search</span>
                    <p className="form-label">Search</p>
                  </div>
                  <span>{this.state.dataCheckout['TOTAL'] ? this.state.dataCheckout['TOTAL']['DATA_SEARCH'] : ''}</span>
                </div>
                <div className={'line'}>
                  <LabelGroup iconName={'person_standing'} text={'PAX unsolved'} />
                  <span>0</span>
                </div>
                <div className={'line'}>
                  <LabelGroup iconName={'calendar'} text={'Lead time'} />
                  <span className={this.state.priority === 'fastest' ? 'big text-primary' : ''}>{this.state.dataCheckout['TOTAL'] ? this.state.dataCheckout['TOTAL']['LEAD'] : ''}</span>
                </div>
                <div className={'line'}>
                  <LabelGroup iconName={'clock'} text={'Time to action'} />
                  <span className={this.state.priority === 'fastest' ? 'big text-primary' : ''}>{this.state.dataCheckout['TOTAL'] ? this.state.dataCheckout['TOTAL']['TIME_TO_ACTION'] : ''}</span>
                </div>
                <div className={'line'}>
                  <LabelGroup iconName={'money_coins'} text={'Total'} />
                  <span className={'price' + (this.state.priority === 'price' ? ' big text-green' : '')}>{this.state.dataCheckout['TOTAL'] ? this.state.dataCheckout['TOTAL']['PRICE'] : ''} €</span>
                </div>
              </div>

              <LinkButton text={'Confirm Payment'} color={'black'} block></LinkButton>
            </aside>
          </div>
        </div>
      </main>
    )
  }
}
