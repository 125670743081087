import React, { Component } from 'react';

export default class Icomoon extends Component {
  render() {
    return <span className={'icomoon-' + this.props.iconName + this.props.className} />
  }
}

Icomoon.defaultProps = {
  className: ' '
}
