import React, { Component } from 'react';

import Icon from '@material-ui/core/Icon';
import Modal from 'react-bootstrap/Modal'

import Button from '../components/Button';
import Icomoon from '../components/Icomoon';

export default class HotelsModal extends Component {
  constructor() {
    super()
    this.state = {
      fastestSelected: false,
      bestPriceSelected: false,
      alternativeSelected: false,
      HotelsPriority: 'fastest',
    }
  }

  componentWillReceiveProps(){

    if(this.props.priority ){
      this.setState({HotelsPriority: this.props.priority});

      if(this.state.HotelsPriority === 'fastest'){
        this.setState({fastestSelected: true});
        this.setState({bestPriceSelected: false});
        this.setState({alternativeSelected: false});

      }else if(this.state.HotelsPriority === 'price'){
        this.setState({fastestSelected: false});
        this.setState({bestPriceSelected: true});
        this.setState({alternativeSelected: false});

      }else if(this.state.HotelsPriority === 'manual'){
        this.setState({fastestSelected: false});
        this.setState({bestPriceSelected: false});
        this.setState({alternativeSelected: true});
      }
    }
    return true;
  }

  handleSelectedSolution = (name) => (e) => {
    e.preventDefault()
    this.setState({
      fastestSelected: false,
      bestPriceSelected: false,
      alternativeSelected: false,
      [name]: true
    })

    if(name === 'fastestSelected'){
      this.setState({HotelsPriority: 'fastest'});
    }else if(name === 'bestPriceSelected'){
      this.setState({HotelsPriority: 'price'});
    }else if(name === 'alternativeSelected'){
      this.setState({HotelsPriority: 'manual'});
    }
  }

  getStars(stars){
    return stars.replace( /\*/g , '<Icon>star</Icon>');
  }

  render() {

    const tableFastest = []
    const tablePrice = []
    const tableAltn = []

    if(this.props.dataALTN.length !== 0){

      for (const [index, value] of this.props.dataALTN.FASTEST.entries() ) {
          if(index !== 0){
            tableFastest.push(
              <tr key={'separator-fastest-'+index} className={'separator'}>
                <td colSpan={6}>&nbsp;</td>
              </tr>
            );
          }
          for (const [index2, value2] of value.DATA.entries() ) {
              if(index2 === 0){

                tableFastest.push(
                  <tr key={'fastest-'+index2+'-'+index} className={'strong'}>
                    <td rowSpan={value.DATA.length} style={{ verticalAlign: 'top', paddingTop: 11, }}>
                      <span className="table-tit">
                        {value.HOTEL}
                      </span>
                    </td>
                    <td>
                      <div className="stars-row" dangerouslySetInnerHTML={{__html: value2.CATEGORY.replace( /\*/g , '<span class="material-icons MuiIcon-root" aria-hidden="true">star</span>') }} />

                    </td>
                    <td>{value2.ROOMS_AVAIL}</td>
                    <td>{value2.CAPACITY}</td>
                    <td>{value2.DISTANCE}</td>

                    <td className={'text-center'}>
                      {index === 0 &&
                        <Button
                          text={this.state.fastestSelected ? 'Selected' : 'Select'}
                          color={this.state.fastestSelected ? 'primary' : 'black-outline'}
                          onClick={this.handleSelectedSolution('fastestSelected')}
                        />
                      }
                      </td>
                  </tr>
                );

              }else{
                tableFastest.push(
                  <tr key={'fastest-'+index2+'-'+index}>
                    <td>{value2.CATEGORY}</td>
                    <td>{value2.ROOMS_AVAIL}</td>
                    <td>{value2.CAPACITY}</td>
                    <td>{value2.DISTANCE}</td>
                    <td className={'text-center'}>
                      <span className={'price' + (this.state.fastestSelected ? ' text-primary' : '')}>{value2.PRICE_PAX}</span>
                    </td>
                  </tr>);
              }
          }
      }

      for (const [index, value] of this.props.dataALTN.PRICE.entries() ) {
          if(index !== 0){
            tablePrice.push(
              <tr key={'separator-price-'+index} className={'separator'}>
                <td colSpan={6}>&nbsp;</td>
              </tr>
            );
          }
          for (const [index2, value2] of value.DATA.entries() ) {
              if(index2 === 0){
                tablePrice.push(
                  <tr key={'price-'+index2+'-'+index} className={'strong'}>
                    <td rowSpan={value.DATA.length} style={{ verticalAlign: 'top', paddingTop: 11, }}>
                      <span className="table-tit">
                        {value.HOTEL}
                      </span>
                    </td>
                    <td>
                    <div className="stars-row" dangerouslySetInnerHTML={{__html: value2.CATEGORY.replace( /\*/g , '<span class="material-icons MuiIcon-root" aria-hidden="true">star</span>') }} />
                    </td>
                    <td>{value2.ROOMS_AVAIL}</td>
                    <td>{value2.CAPACITY}</td>
                    <td>{value2.DISTANCE ? value2.DISTANCE : '&nbsp;'}</td>
                    <td className={'text-center'}>
                      {index === 0 &&
                      <Button
                        text={this.state.bestPriceSelected ? 'Selected' : 'Select'}
                        color={this.state.bestPriceSelected ? 'primary' : 'black-outline'}
                        onClick={this.handleSelectedSolution('bestPriceSelected')}
                      />
                      }
                    </td>
                  </tr>
                );
              }else{
                tablePrice.push(
                  <tr key={'price-'+index2+'-'+index}>
                    <td>{value2.CATEGORY}</td>
                    <td>{value2.ROOMS_AVAIL}</td>
                    <td>{value2.CAPACITY}</td>
                    <td>{value2.DISTANCE}</td>
                    <td className={'text-center'}>
                      <span className={'price' + (this.state.bestPriceSelected ? ' text-primary' : '')}>{value2.PRICE_PAX}</span>
                    </td>
                  </tr>);
              }
          }
      }

      for (const [index, value] of this.props.dataALTN.ALTN.entries() ) {
          if(index !== 0){
            tableAltn.push(
              <tr key={'separator-altn-'+index} className={'separator'}>
                <td colSpan={6}>&nbsp;</td>
              </tr>
            );
          }
          for (const [index2, value2] of value.DATA.entries() ) {
              if(index2 === 0){
                tableAltn.push(
                  <tr key={'altn-'+index2+'-'+index} className={'strong'}>
                    <td rowSpan={value.DATA.length} style={{ verticalAlign: 'top', paddingTop: 11, }}>
                      <span className="table-tit">
                        {value.HOTEL}
                      </span>
                    </td>
                    <td>
                      <div className="stars-row" dangerouslySetInnerHTML={{__html: value2.CATEGORY.replace( /\*/g , '<span class="material-icons MuiIcon-root" aria-hidden="true">star</span>') }} />
                    </td>
                    <td>{value2.ROOMS_AVAIL}</td>
                    <td>{value2.CAPACITY}</td>
                    <td>{value2.DISTANCE}</td>
                    <td className={'text-center'}>
                    {index === 0 &&
                      <Button
                        text={this.state.alternativeSelected ? 'Selected' : 'Select'}
                        color={this.state.alternativeSelected ? 'primary' : 'black-outline'}
                        onClick={this.handleSelectedSolution('alternativeSelected')}
                      />
                    }
                    </td>
                  </tr>);
              }else{
                tableAltn.push(
                  <tr key={'altn-'+index2+'-'+index}>
                    <td>{value2.CATEGORY}</td>
                    <td>{value2.ROOMS_AVAIL}</td>
                    <td>{value2.CAPACITY}</td>
                    <td>{value2.DISTANCE}</td>
                    <td className={'text-center'}>
                      <span className={'price' + (this.state.alternativeSelected ? ' text-primary' : '')}>{value2.PRICE_PAX}</span>
                    </td>
                  </tr>);
              }
          }
      }

    }

    return(
      <Modal
        show={this.props.show}
        onHide={this.props.onHide}
        dialogClassName="hotels-modal"
        size={'xl'}
      >
        <Modal.Header closeButton>
          <div className={'modal-solution-header'}>
            <div className={'flex-row'}>
              <Icomoon iconName={'hotel_bed'} />
              <Modal.Title className="tit"><strong>Hotels</strong> - Solutions</Modal.Title>
            </div>
            <Button
              text={'Apply selected solution'}
              color={'orange'}
              iconName={'check'}
              onClick={ () => {
                this.props.onHide();
                this.props.changeData('Hotels', (this.state.HotelsPriority === 'price' ? 'SPECIAL_PRICE' : this.state.HotelsPriority ) )
              }}
              />
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className={'table-responsive'}>
            <table className={'table'}>
              <thead>
                <tr>
                  <th>Hotel</th>
                  <th>Category</th>
                  <th>Rooms avail.</th>
                  <th>Capacity</th>
                  <th>Distance</th>
                  <th className={'text-center'}>Price/PAX</th>
                </tr>
              </thead>
              <tbody>

                <tr>
                  <td colSpan={6} className={'title-td bg-primary'}>
                    <span className="flex-row">
                      <Icon>access_time</Icon>
                      <span className={'tit'}>Fastest Solution</span>
                    </span>
                  </td>
                </tr>
                {tableFastest}

                <tr>
                  <td colSpan={6} className={'title-td bg-green'}>
                    <span className="flex-row">
                      <Icon>money</Icon>
                      <span className={'tit'}>Best Price Solution</span>
                    </span>
                  </td>
                </tr>
                {tablePrice}

                <tr>
                  <td colSpan={6} className={'title-td bg-yellow'} style={{ paddingTop: 5, paddingBottom: 5 }}>
                    <span className="flex-row">
                      <Icon fontSize={'small'}>check_circle_outline</Icon>
                      <span className={'tit'}>Alternative Solutions</span>
                    </span>
                  </td>
                </tr>
                {tableAltn}

              </tbody>
            </table>
          </div>
        </Modal.Body>
      </Modal>
    )
  }
}
