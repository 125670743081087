import React, { Component } from 'react';

import Icon from '@material-ui/core/Icon';

export default class Topbar extends Component {
  render() {
    return(
      <header className="topbar">
        <div className="container container-fluid">
          <a href="/" className={'logo'}>
            <img src={require('../img/safly-logo.png')} alt="Safly"/>
          </a>
          <nav className={'topbar-nav'}>
            <ul className={'navigation'}>
              <li><a href="/">About</a></li>
              <li><a href="/home">The solution</a></li>
              <li><a href="/">Our platform</a></li>
              <li><a href="/">Team</a></li>
            </ul>
            {
              (this.props.loggedUser === true) ?
                <div className={'user'}>
                  <Icon color="primary" fontSize="large">account_circle</Icon>
                  <div>
                    <span className={'name'}>Username</span>
                    <a href="/" className={'logout'}>Logout</a>
                  </div>
                </div> : ''
            }

          </nav>
        </div>
      </header>
    )
  }
}
