import React, { Component } from 'react';

export default class FormControl extends Component {
  render() {
    const { disabled, placeholder, size, style, type, name, value, onChange, pattern, step, ref } = this.props;
    return <input
      className={'form-control' + (size === 'large' ? ' size-lg' : '')}
      disabled={disabled}
      placeholder={placeholder}
      style={style}
      type={type}
      ref={ref}
      value={value}
      onChange={onChange}
      pattern={pattern}
      step={step}
      name={name}
    />
  }
}

FormControl.defaultProps = {
  type: 'text',
  placeholder: '',
  disabled: false
}
