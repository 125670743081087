import React, { Component } from 'react';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Icon from '@material-ui/core/Icon';
import Radio from '@material-ui/core/Radio';

export default class FormRadio extends Component {
  render() {

    const { description, disabled, name, onChange, required, value, label } = this.props;

    return(
      <FormControlLabel
        className={'form-radio'}
        control={
          <Radio
            checkedIcon={<Icon fontSize={'small'}>check_circle</Icon>}
            color={'default'}
            disabled={disabled}
            disableRipple={true}
            name={name}
            required={required}
            size={'small'}
            value={value}
            onChange={onChange}
          />
        }
        label={
          <div className={'radio-label'}>
            <span className={'main-text'}>{label}</span>
            <span className={'secondary-text'}>{description}</span>
          </div>
        }
      />
    )
  }
}
