import React, { Component } from 'react';
import Icomoon from '../components/Icomoon';

export default class ResultRow extends Component {
  render() {
    const {  description, term, onClick, removable } = this.props;
    if( removable === true ) {
      return(
        <div className="result-row boxed-item">
          <span className="term">{term}</span>
          <span className="description">
            <span>{description}</span>
            <button className={'action'} onClick={onClick}>
              <Icomoon iconName={'trash'} />
            </button>
          </span>
        </div>
      )
    }
    else {
      return(
        <div className="result-row boxed-item">
          <span className="term">{term}</span>
          <span className="description">
            <span>{description}</span>
          </span>
        </div>
      )
    }

  }
}

ResultRow.defaultOptions = {
  removable: false
}
