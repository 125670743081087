import React, { Component } from 'react';
import Icomoon from '../components/Icomoon';

export default class TimelineManual extends Component {
  render() {
    return(
      <div className="box timeline-box">
        <div className="actions-row">
          <p className={'start-action'}>Now<br />{this.props.day}/{this.props.month}</p>
          <div className={'action-step'} style={{ width: '13.5%' }}>
            <Icomoon iconName={'bus'} />
            <p>2h 45min</p>
          </div>
          <div className={'action-group'} style={{ width: '55%' }}>
            <Icomoon iconName={'hotel_bed'} />
            <p>9h</p>
          </div>
          <div className={'action-group'} style={{ width: '13%' }}>
            <Icomoon iconName={'bus'} />
            <p>2h 45min</p>
          </div>
          <div className={'action-group'} style={{ width: '18.5%' }}>
            <Icomoon iconName={'flight_departure'} />
            <p>3h 24min</p>
          </div>
          <p className={'end-action'}>Solved<br />{this.props.finishdate}</p>
        </div>
        <div className="timeline">
          <span className="timeline-circle bg-orange"></span>
          <span className="timeline-line"></span>
          <span className="timeline-circle bg-green"></span>
          <span className="step" style={{ left: '16%' }}></span>
          <span className="step" style={{ left: '66.2%' }}></span>
          <span className="step" style={{ left: '78%' }}></span>
        </div>
        <div className="times-row">
          <p className={'start-time'}>{this.props.startdate}</p>
          {/*
          <p className={'step-time'} style={{ left: '14.75%' }}>23:00</p>
          <p className={'step-time'} style={{ left: '65.3%' }}>8:00</p>
          <p className={'step-time'} style={{ left: '77.1%' }}>10:45</p>
          */}
          <p className={'end-time'}>{this.props.finishhours}</p>
        </div>
      </div>
    )
  }
}
