import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import Icomoon from '../components/Icomoon';

export default class LinkButton extends Component {


  render() {
    const { block, color, iconName, to, text, title, value, datas, date, datasback } = this.props;
    return(
      <Link
        className={`btn btn-${color}` + (block === true ? ' block' : '')}
        title={title}
        to={{ pathname: to, state: { priority: value, datas: datas, date: date, datasback: datasback} }}
      >
        <span className={'text'}>{text}</span>
        <Icomoon iconName={iconName} className={iconName === false ? ' hidden' : ''} />
      </Link>
    )
  }
}

LinkButton.defaultProps = {
  color: 'primary',
  block: false,
  iconName: false
}
