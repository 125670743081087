import React, { Component } from 'react';

import StyledSwitch from '../components/StyledSwitch';

export default class BoxSolutions extends Component {
  render() {
    const { amount, children, checked, onSwitchChange, alternativeSolutions, onModalToggleClick } = this.props;
    return(
      <div className="box-body">
        <div className={'solution-amount'}>
          <span className="amount">{ amount } €</span>
          <StyledSwitch
            checked={ checked }
            onChange={ onSwitchChange }
          />
          <button className={'btn-text'} onClick={ onModalToggleClick }>{ alternativeSolutions } alternative solutions</button>
        </div>
        { children }
      </div>
    )
  }
}
