import React, { Component } from 'react';

import LabelGroup from '../components/LabelGroup';
import ResultRow from '../components/ResultRow';


export default class LogisticsBoxResume extends Component {
  render() {
    return(
      <div className={'box-body'} style={{ height: this.props.bodyHeight }}>
        <div className="result-row boxed-item" style={{ marginBottom: 12, marginTop: -4 }}>
          <span className="term">ROUND TRIP</span>
        </div>
        <div className="form-group">
          <LabelGroup
            iconName={'flight_departure'}
            text={'From'}
          />
          <p>BCN - Barcelona El Prat</p>
        </div>
        <div className="form-group">
          <LabelGroup
            iconName={'flight_arrival'}
            text={'To'}
          />
          <p>NTE - Nantes</p>
        </div>
        <div className={'form-row'}>
          <div className="form-group percent-40">
            <LabelGroup
              iconName={'person_standing'}
              text={'PAX'}
            />
            <p>2</p>
          </div>
        </div>
        <div>
          <LabelGroup
            iconName={'case'}
            text={'Freight'}
          />
          <div className="results-list">
            <ResultRow
              description={'0,5 m3'}
              term={'3 kg'}
            />
            <ResultRow
              description={'0,5 m3'}
              term={'5 kg'}
            />
          </div>
          <h2 className="total-title">Total</h2>
          <ResultRow
            description={'1 m3'}
            term={'8 kg'}
          />
        </div>
      </div>
    )
  }
}
