import React, { Component } from 'react';

import AcmiModal from '../components/AcmiModal';
import AcmiBoxResume from '../components/AcmiBoxResume';
import BusBoxResume from '../components/BusBoxResume';
import BusModal from '../components/BusModal';
import BoxHeader from '../components/BoxHeader';
import BoxSolutions from '../components/BoxSolution';
import HotelsBoxResume from '../components/HotelsBoxResume';
import HotelsModal from '../components/HotelsModal';
import Icomoon from '../components/Icomoon';
import LogisticsBoxResume from '../components/LogisticsBoxResume';
import OtaBoxResume from '../components/OtaBoxResume';
import OtaModal from '../components/OtaModal';
import LabelGroup from '../components/LabelGroup';
import LinkButton from '../components/LinkButton';
import LogisticsModal from '../components/LogisticsModal';
import SolutionTypeTitle from '../components/SolutionTypeTitle';
import StyledSwitch from '../components/StyledSwitch';

import NProgress from 'nprogress';
import 'nprogress/nprogress.css';

import axios from 'axios';

export default class ResultsScreen extends Component {

  constructor(props) {
    super(props)
    this.state = {
      dataApi: [],
      dataAcmi: [],
      dataHotels: [],
      dataBus: [],
      dataLogistics: [],
      dataOta: [],
      checkOutPrice: '',
      checkOutTime: '',
      checkOutLead: '',
      acmiSelected: true,
      acmiModalVisible: false,
      hotelsSelected: true,
      hotelsModalVisible: false,
      busSelected: true,
      busModalVisible: false,
      logisticsSelected: true,
      logisticsModalVisible: false,
      otaSelected: true,
      otaModalVisible: false,
      priority: '', /* fastest, price or manual */
      dateSearch: null,
      dataBestPriceLeadTime: '',
    }
  }

    componentDidMount() {


        window.scrollTo(0, 0)

        if(typeof this.props.location.state !== 'undefined' && typeof this.props.location.state['priority'] !== 'undefined' ){
          var priority_loc = this.props.location.state.priority;
          this.setState({ priority: priority_loc })
        }

        var date = null;
        if(typeof this.props.location.state !== 'undefined' && typeof this.props.location.state['date'] !== 'undefined' ){
          date = this.props.location.state.date;
          this.setState({ dateSearch: date });

        }else{
          var d = new Date();
          this.setState({ dateSearch: d });
        }
        //console.log(this.props.location.state['priority']);
        //console.log(this.props.priority);
        //console.log(this.state.priority);

        NProgress.start();
        setTimeout(function() {

        axios.post('https://saflyapi.omatech.com/api/demo/napoles?api_key=key_cur_prod_fnPqT5xQEi5Vcb9wKwbCf65c3BjVGyBB', {
            headers: {
                'Content-Type': 'application/json',
            },
        }).then(res => {

            const dataApi = res.data;
            this.setState({ dataApi });

            //BACK BUTTON
            if(typeof this.props.location.state !== 'undefined' && typeof this.props.location.state['datasback'] !== 'undefined' ){

              var datasback = this.props.location.state.datasback;

              if(datasback.ACMI){
                var acmi = datasback.ACMI.RESULT;
                this.setState({ 'dataAcmi': acmi });
                this.setState({ acmiSelected: datasback['ACMI']['ACTIVE'] });
              }

              if(datasback.HOTELS){
                var hotels = datasback.HOTELS.RESULT;
                this.setState({ 'dataHotels': hotels });
                this.setState({ hotelsSelected: datasback['HOTELS']['ACTIVE'] });
              }

              if(datasback.BUS){
                var bus = datasback.BUS.RESULT;
                this.setState({ 'dataBus': bus });
                this.setState({ busSelected: datasback['BUS']['ACTIVE'] });
              }

              if(datasback.LOGISTICS){
                var logistics = datasback.LOGISTICS.RESULT;
                this.setState({ 'dataLogistics': logistics });
                this.setState({ logisticsSelected: datasback['LOGISTICS']['ACTIVE'] });
              }

              if(datasback.OTA){
                var ota = datasback.OTA.RESULT;
                this.setState({ 'dataOta': ota });
                this.setState({ otaSelected: datasback['OTA']['ACTIVE'] });
              }

            }else{
              const dataAcmi = this.setDataApi('ACMI', this.state.priority);
              this.setState({dataAcmi});
              //console.log(dataAcmi);

              const dataHotels = this.setDataApi('Hotels', this.state.priority);
              this.setState({dataHotels});

              const dataBus = this.setDataApi('Bus', this.state.priority);
              this.setState({dataBus});

              const dataLogistics = this.setDataApi('Logistics', this.state.priority);
              this.setState({dataLogistics});

              const dataOta = this.setDataApi('Ota', this.state.priority);
              this.setState({dataOta});

              this.updateSelected();
            }

            this.setCheckOutData();

        }).catch(function (error) {
            console.log('Error Api');
        });

        NProgress.done();
      }.bind(this), 3000)
    };

    getDate( d ){
        var datestring = ("0" + d.getDate()).slice(-2) + "-" + ("0"+(d.getMonth()+1)).slice(-2) + "-" +
        d.getFullYear() + " " + ("0" + d.getHours()).slice(-2) + ":" + ("0" + d.getMinutes()).slice(-2);
        return datestring;
    }

   timeout(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

  handlePriority = async () => {
    NProgress.start();

    await this.timeout(1000);

    if (this.state.priority === 'price') {
      await this.setDataApi('ACMI', 'fastest');
      await this.setDataApi('Hotels', 'fastest');
      await this.setDataApi('Bus', 'fastest');
      await this.setDataApi('Logistics', 'fastest');
      await this.setDataApi('Ota', 'fastest');


      await this.setState({priority: 'fastest'} );


      await this.updateSelected();
      await this.setCheckOutData();

    }else {
        await this.setDataApi('ACMI', 'price');
        await this.setDataApi('Hotels', 'price');
        await this.setDataApi('Bus', 'price');
        await this.setDataApi('Logistics', 'price');
        await this.setDataApi('Ota', 'price');

        await this.setState({priority: 'price'});

        await  this.updateSelected();
        await this.setCheckOutData();
    }

    NProgress.done();
  }

  handleBoxes = (boxName) => (e) => {

    if( boxName === 'hotelsSelected' || boxName === 'busSelected' ){
      this.setState({
        hotelsSelected: e.target.checked,
        busSelected: e.target.checked
      }, function(){
        this.setCheckOutData();
      });

    }else{
      this.setState({
        [boxName]: e.target.checked,
      }, function(){
        this.setCheckOutData();
      });
    }
  }

  updateSelected(){
      this.setState({acmiSelected: this.state.dataAcmi['ACTIVE']});
      this.setState({hotelsSelected: this.state.dataHotels['ACTIVE']});
      this.setState({busSelected: this.state.dataBus['ACTIVE']});
      this.setState({logisticsSelected: this.state.dataLogistics['ACTIVE']});
      this.setState({otaSelected: this.state.dataOta['ACTIVE']});
  }

  createShowingBoxTitle = (priority) => {
    if(priority === 'price') {
      return <h2 className={'tit'}>Showing <strong className={'text-green'}>Best Price Solutions</strong> for <strong>NOW</strong></h2>
    }
    else if (priority === 'fastest') {
      return <h2 className={'tit'}>Showing <strong className={'text-primary'}>Fastest Solutions</strong> for <strong>NOW</strong></h2>
    }
    else {
      return <h2 className={'tit'}>Showing <strong>Manual Solutions</strong> for <strong>NOW</strong></h2>
    }
  }

  changeData = async (value, column) => {

    await this.setDataApi( value, column );
    await this.setState( { priority: this.getPriorityGeneral() });
    await this.setCheckOutData();
  }

  getPriorityGeneral(){
      if(this.state.dataAcmi['TYPE'] === 'MANUAL' || this.state.dataHotels['TYPE'] === 'MANUAL' || this.state.dataBus['TYPE'] === 'MANUAL' || this.state.dataLogistics['TYPE'] === 'MANUAL' || this.state.dataOta['TYPE'] === 'MANUAL'){
        return 'manual';
      }else if( this.state.dataAcmi['TYPE'] === 'FASTEST' && this.state.dataHotels['TYPE'] === 'FASTEST' && this.state.dataBus['TYPE'] === 'FASTEST' && this.state.dataLogistics['TYPE'] === 'FASTEST' && this.state.dataOta['TYPE'] === 'FASTEST') {
        return 'fastest';

      }else if( this.state.dataAcmi['TYPE'] === 'PRICE' && this.state.dataHotels['TYPE'] === 'PRICE' && this.state.dataBus['TYPE'] === 'PRICE' && this.state.dataLogistics['TYPE'] === 'PRICE' && this.state.dataOta['TYPE'] === 'PRICE' ){
        return 'price';

      }else{
        return 'manual';
      }
  }

  setDataApi(value, priority){

      if(value === 'ACMI'){
          const dataAcmi = this.getDataResultFromType(value, priority);
          this.setState({dataAcmi});
          return dataAcmi;

      }else if(value === 'Hotels'){

          const dataBus = this.getDataResultFromType('Bus', priority);
          this.setState({dataBus});

          const dataHotels = this.getDataResultFromType(value, priority);
          this.setState({dataHotels});
          return dataHotels;

      }else if( value === 'Bus'){
          const dataBus = this.getDataResultFromType(value, priority);
          this.setState({dataBus});
          return dataBus;

      }else if( value === 'Logistics'){
          const dataLogistics = this.getDataResultFromType(value, priority);
          this.setState({dataLogistics});
          return dataLogistics;

      }else if( value === 'Ota' ){
          const dataOta = this.getDataResultFromType(value, priority);
          this.setState({dataOta});
          return dataOta;

      }else{
          return null;

      }
  }

  getDataResultFromType(value, type ){
    if(value && type && this.state.dataApi.length !== 0){
      return this.state.dataApi[value]['RESULTS'][type.toUpperCase()];
    }else{
      return '';
    }

  }

  setCheckOutData(){
      var price = 0;

      //Price
      if(this.state.acmiSelected && this.state.dataAcmi['PRICE']){
        price = price + parseFloat( this.state.dataAcmi['PRICE'].replace(".", "").replace(",", ".") );
      }
      if(this.state.hotelsSelected && this.state.dataHotels['PRICE']){
        price = price + parseFloat( this.state.dataHotels['PRICE'].replace(".", "").replace(",", ".") );
      }
      if(this.state.busSelected && this.state.dataBus['PRICE']){
        price = price + parseFloat( this.state.dataBus['PRICE'].replace(".", "").replace(",", ".") );
      }
      if(this.state.logisticsSelected && this.state.dataLogistics['PRICE']){
        price = price + parseFloat( this.state.dataLogistics['PRICE'].replace(".", "").replace(",", ".") );
      }
      if(this.state.otaSelected && this.state.dataOta['PRICE']){
        price = price + parseFloat( this.state.dataOta['PRICE'].replace(".", "").replace(",", ".") );
      }

      //Time To Action
      var array_times = [];
      if(this.state.acmiSelected && this.state.dataAcmi['TIME_TO_ACTION_MINUTES']){
        array_times.push(this.state.dataAcmi['TIME_TO_ACTION_MINUTES']);
      }
      if(this.state.hotelsSelected && this.state.dataHotels['TIME_TO_ACTION_MINUTES']){
        array_times.push(this.state.dataHotels['TIME_TO_ACTION_MINUTES']);
      }
      if(this.state.busSelected && this.state.dataBus['TIME_TO_ACTION_MINUTES']){
        array_times.push(this.state.dataBus['TIME_TO_ACTION_MINUTES']);
      }
      if(this.state.logisticsSelected && this.state.dataLogistics['TIME_TO_ACTION_MINUTES']){
        array_times.push(this.state.dataLogistics['TIME_TO_ACTION_MINUTES']);
      }
      if(this.state.otaSelected && this.state.dataOta['TIME_TO_ACTION_MINUTES']){
        array_times.push(this.state.dataOta['TIME_TO_ACTION_MINUTES']);
      }
      var time = Math.min( ...array_times );
      var time_end = 0;
      if(time === this.state.dataAcmi['TIME_TO_ACTION_MINUTES']){
          time_end = this.state.dataAcmi['TIME_TO_ACTION'];
      }else if( time === this.state.dataBus['TIME_TO_ACTION_MINUTES']){
        time_end = this.state.dataBus['TIME_TO_ACTION'];
      }else if( time === this.state.dataLogistics['TIME_TO_ACTION_MINUTES']){
        time_end = this.state.dataLogistics['TIME_TO_ACTION'];
      }else if( time === this.state.dataOta['TIME_TO_ACTION_MINUTES']){
        time_end = this.state.dataOta['TIME_TO_ACTION'];
      }

      //Lead Time
      var array_lead = [];
      if(this.state.acmiSelected && this.state.dataAcmi['LEAD_TIME_MINUTES']){

        var date_acmi = this.getformatDate(this.state.dataAcmi['LEAD_TIME_MINUTES'], 'T0')
        array_lead.push( date_acmi );

        var acmi_hour =   ("0" + date_acmi.getHours()).slice(-2) + ":" + ("0" + date_acmi.getMinutes()).slice(-2)
        this.setState({dataBestPriceLeadTime:  acmi_hour });
      }

      if(this.state.hotelsSelected){

        var date_hotels = this.state.dateSearch;

        if(this.state.dataHotels['TYPE'] === 'PRICE' || this.state.dataHotels['TYPE'] === 'SPECIAL_PRICE'){
          //T1 at 21:00
          date_hotels = new Date( date_hotels.getTime() + (60 * 60 * 24 * 1000));
          date_hotels.setHours(21);
          date_hotels.setMinutes(0);

          array_lead.push( date_hotels );

        }else if( this.state.dataHotels['TYPE'] === 'FASTEST' ){
          //T0 at 21:00
          date_hotels = new Date( date_hotels.getTime() );
          date_hotels.setHours(19);
          date_hotels.setMinutes(0);

          array_lead.push( date_hotels );

        }else{

            if(this.state.dataHotels['LEAD_TIME_MINUTES']){
              array_lead.push( this.getformatDate(this.state.dataHotels['LEAD_TIME_MINUTES'], 'T0') );
            }
        }

      }
      if(this.state.busSelected && this.state.dataBus['LEAD_TIME_MINUTES']){
        array_lead.push( this.getformatDate(this.state.dataBus['LEAD_TIME_MINUTES'], 'T0') );
      }
      if(this.state.logisticsSelected && this.state.dataLogistics['LEAD_TIME_MINUTES']){
        array_lead.push( this.getformatDate(this.state.dataLogistics['LEAD_TIME_MINUTES'], 'T0') );
      }

      if(this.state.otaSelected ){

        var date_ota = this.state.dateSearch;
        //MIRAR si es price t1 , manual / alternative t0
        if(this.state.dataOta['TYPE'] === 'PRICE'){

          date_ota = new Date( date_ota.getTime() + (60 * 60 * 24 * 1000) + (60 * 60 * 24 * 1000) );
          date_ota.setHours(0);
          date_ota.setMinutes(25);

        }else if( this.state.dataOta['TYPE'] === 'FASTEST' ){

          date_ota = new Date( date_ota.getTime());
          date_ota.setHours(22);
          date_ota.setMinutes(55);
        }else{

          date_ota = new Date( date_ota.getTime());
          date_ota.setHours(23);
          date_ota.setMinutes(55);
        }

        array_lead.push( date_ota );
      }

      if(typeof array_lead !== 'undefined' && array_lead.length > 0 ){
        var maxDate=new Date(Math.max.apply(null,array_lead));
        this.setState({checkOutLead: this.getDateFormat(maxDate) });
      }else{
        this.setState({checkOutLead: 0 });
      }

      this.setState({checkOutTime: time_end});
      this.setState({checkOutPrice: Intl.NumberFormat("es-ES").format(price)});
  }

  getDataForCheckout(){
      var datas = [];

      if(typeof this.state.dataApi.length === 'undefined' && typeof this.state.dataAcmi.length === 'undefined'){

        if(this.state.acmiSelected){
          datas['ACMI'] = {...this.state.dataApi['ACMI']} ;
          delete datas['ACMI']['RESULTS'];
          datas['ACMI']['RESULT'] = {...this.state.dataAcmi};
        }else{
          delete datas['ACMI'];
        }

        if(this.state.hotelsSelected){
          datas['HOTELS'] = {...this.state.dataApi['Hotels']}
          delete datas['HOTELS']['RESULTS'];
          datas['HOTELS']['RESULT'] = {...this.state.dataHotels};
        }else{
          delete datas['HOTELS'];
        }

        if(this.state.busSelected){
          datas['BUS'] = {...this.state.dataApi['Bus']}
          delete datas['BUS']['RESULTS'];
          datas['BUS']['RESULT'] = {...this.state.dataBus};
        }else{
          delete datas['BUS'];
        }

        if(this.state.logisticsSelected){
          datas['LOGISTICS'] = {...this.state.dataApi['Logistics']}
          delete datas['LOGISTICS']['RESULTS'];
          datas['LOGISTICS']['RESULT'] = {...this.state.dataLogistics};
        }else{
          delete datas['LOGISTICS'];
        }

        if(this.state.otaSelected){
          datas['OTA'] = {...this.state.dataApi['Ota']}
          delete datas['OTA']['RESULTS'];
          datas['OTA']['RESULT'] = {...this.state.dataOta};
        }else{
          delete datas['OTA'];
        }

        datas['TOTAL'] = [];

        datas['TOTAL']['BEST_PRICE_ACMI'] = this.state.dataBestPriceLeadTime;
        datas['TOTAL']['DATA_SEARCH_COMPLETE'] = this.state.dateSearch;
        datas['TOTAL']['DATA_SEARCH'] = this.getDateFormat(this.state.dateSearch);
        datas['TOTAL']['LEAD'] = this.state.checkOutLead;
        datas['TOTAL']['PRICE'] = this.state.checkOutPrice;
        datas['TOTAL']['TIME_TO_ACTION'] = this.state.checkOutTime;

      }

      return datas;
  }

  getTypeColor(type){
    if(type === 'MANUAL'){
      return 'alternative';
    }else{
      return type.toLowerCase();
    }
  }

  formatDate( card ){

    var date = this.state.dateSearch;

    if(card.indexOf('{T') > -1){

    var search = card.match(/{(.*?)}/gi);

    search.forEach((val, i) => {

        var new_date = date;

        var val2 = val.replace("{", "");
        val2 = val2.replace("}", "");

        var datas = val2.split("+");

        //With minutes
        var datestring = "";
        if(datas[1]){
          var minutes = parseInt(datas[1]);
          new_date = new Date(new_date.getTime() + minutes*60000);

          if(datas[0] === 'T1'){
            new_date = new Date(new_date.getTime() + (60 * 60 * 24 * 1000) );
            datestring = ("0" + new_date.getDate()).slice(-2) + "/" + ("0"+(new_date.getMonth()+1)).slice(-2) + " " + ("0" + new_date.getHours()).slice(-2) + ":" + ("0" + new_date.getMinutes()).slice(-2);

          }else if(datas[0] === 'T2'){
            new_date = new Date(new_date.getTime() + (60 * 60 * 24 * 1000) + (60 * 60 * 24 * 1000));
            datestring = ("0" + new_date.getDate()).slice(-2) + "/" + ("0"+(new_date.getMonth()+1)).slice(-2) + " " + ("0" + new_date.getHours()).slice(-2) + ":" + ("0" + new_date.getMinutes()).slice(-2);

          }else{
            datestring = ("0" + new_date.getDate()).slice(-2) + "/" + ("0"+(new_date.getMonth()+1)).slice(-2) + " " + ("0" + new_date.getHours()).slice(-2) + ":" + ("0" + new_date.getMinutes()).slice(-2);
          }

        }else{

          if(datas[0] === 'T1'){
            new_date = new Date(new_date.getTime() + (60 * 60 * 24 * 1000));
            datestring = ("0" + new_date.getDate()).slice(-2) + "/" + ("0"+(new_date.getMonth()+1)).slice(-2);

          }else if(datas[0] === 'T2'){
            new_date = new Date(new_date.getTime() + (60 * 60 * 24 * 1000) + (60 * 60 * 24 * 1000));
            datestring = ("0" + new_date.getDate()).slice(-2) + "/" + ("0"+(new_date.getMonth()+1)).slice(-2);
          }else{
            datestring = ("0" + new_date.getDate()).slice(-2) + "/" + ("0"+(new_date.getMonth()+1)).slice(-2);
          }
        }
        card = card.replace(val, datestring);

      });
    }

    return card;
  }

  getformatDate( minutes, t ){
    var date = this.state.dateSearch;

    var date2 = new Date(date.getTime() + minutes*60000);

    if(t === 'T1'){
      date2 = new Date(date.getTime() + (60 * 60 * 24 * 1000) );
    }else if(t === 'T2'){
      date2 = new Date(date.getTime() + (60 * 60 * 24 * 1000) + (60 * 60 * 24 * 1000) );

    }else{
    }

    return date2;
  }

  getDateFormat(date){
    return ("0" + date.getDate()).slice(-2) + "/" + ("0"+(date.getMonth()+1)).slice(-2) + " " + ("0" + date.getHours()).slice(-2) + ":" + ("0" + date.getMinutes()).slice(-2);
  }

getDataForBack(){
  var datas = [];

  if(typeof this.state.dataApi.length === 'undefined' && typeof this.state.dataAcmi.length === 'undefined'){
      datas['ACMI'] = []
      datas['ACMI']['RESULT'] = this.state.dataAcmi;
      datas['ACMI']['ACTIVE'] = this.state.acmiSelected;

      datas['HOTELS'] = [];
      datas['HOTELS']['RESULT'] = this.state.dataHotels;
      datas['HOTELS']['ACTIVE'] = this.state.hotelsSelected;

      datas['BUS'] = [];
      datas['BUS']['RESULT'] = this.state.dataBus;
      datas['BUS']['ACTIVE'] = this.state.busSelected;

      datas['LOGISTICS'] = [];
      datas['LOGISTICS']['RESULT'] = this.state.dataLogistics;
      datas['LOGISTICS']['ACTIVE'] = this.state.logisticsSelected;

      datas['OTA'] = [];
      datas['OTA']['RESULT'] = this.state.dataOta;
      datas['OTA']['ACTIVE'] = this.state.otaSelected;
  }

  return datas;
}

  render() {

    const bodyTopHeight = 412;

    return(

      <main className="results-screen image-bg">
        <form action="" className={'form'}>
          <div className="container">

            {/* SHOWING BOX */}
            <div className="box showing-box">
              {this.createShowingBoxTitle(this.state.priority)}
              <LinkButton
                text={'NEW SEARCH'}
                iconName={'refresh'}
                to={'/home'}
              />
            </div>

            <div className="boxes-row">

              {/* ACMI BOX */}
              <div className={'box acmi-box' + (this.state.acmiSelected ? '' : ' disabled')}>
                <BoxHeader
                  iconName={'flight_departure'}
                  title={'ACMI'}
                  tooltipDisabled={!this.state.acmiSelected}
                  infoText={"With our leading airline providers, we are ready to find subcharter solutions in a matter of minutes. If you have an AOG without an internal solution, we can provide you our range of availabilities in real time and costs."}
                />
                <AcmiBoxResume bodyHeight={bodyTopHeight} dataApi={this.state.dataApi['ACMI']} />

                <SolutionTypeTitle type={this.state.dataAcmi['TYPE'] ? this.getTypeColor(this.state.dataAcmi['TYPE'] ) : this.state.priority } />

                <BoxSolutions
                  amount={this.state.dataAcmi ? (this.state.dataAcmi.PRICE ) : ('') }
                  alternativeSolutions={this.state.dataAcmi ? (this.state.dataAcmi.SOLUTIONS ) : ('') }
                  checked={this.state.acmiSelected}
                  onSwitchChange={this.handleBoxes('acmiSelected')}
                  onModalToggleClick={(e) => {
                    this.setState({acmiModalVisible: true});
                    e.preventDefault();
                  }}
                  children={ this.state.dataAcmi.length !== 0  ? (<div dangerouslySetInnerHTML={{__html: this.formatDate( this.state.dataAcmi.CARD  )}} />) : ('')}
                />
              </div>

              <AcmiModal
                show={this.state.acmiModalVisible}
                onHide={() => this.setState({acmiModalVisible: false}) }
                dataALTN={ this.getDataResultFromType('ACMI','ALTN')}
                priority={this.state.dataAcmi['TYPE'] ? this.state.dataAcmi['TYPE'].toLowerCase() : this.state.priority }
                changeData={this.changeData }

              />

              {/* HOTELS BOX */}
              <div className={'box hotels-box' + (this.state.hotelsSelected ? '' : ' disabled')}>
                <BoxHeader
                  iconName={'hotel_bed'}
                  title={'HOTELS'}
                  tooltipDisabled={!this.state.hotelsSelected}
                  infoText={"Depending on the disruption, sometimes passengers must spend an overnight, as there is no feasible solution from the airline to transport them to their destinations. We have a range of hotels near the airport to give a fast solution."}
                />
                <HotelsBoxResume bodyHeight={bodyTopHeight}/>
                <SolutionTypeTitle type={this.state.dataHotels['TYPE'] ? this.getTypeColor(this.state.dataHotels['TYPE'] ) : this.state.priority } />
                <BoxSolutions
                  amount={this.state.dataHotels ? (this.state.dataHotels.PRICE ) : ('') }
                  alternativeSolutions={this.state.dataHotels ? (this.state.dataHotels.SOLUTIONS ) : ('') }
                  checked={this.state.hotelsSelected}
                  onSwitchChange={this.handleBoxes('hotelsSelected')}
                  onModalToggleClick={(e) => {
                    this.setState({hotelsModalVisible: true});
                    e.preventDefault();
                  }}
                  children={ this.state.dataHotels.length !== 0  ? (<div dangerouslySetInnerHTML={{__html: this.formatDate( this.state.dataHotels.CARD  )}} />) : ('')}

                />
              </div>

              <HotelsModal
                show={this.state.hotelsModalVisible}
                onHide={() => this.setState({hotelsModalVisible: false})}
                dataALTN={ this.getDataResultFromType('Hotels','ALTN')}
                priority={this.state.dataHotels['TYPE'] ? this.state.dataHotels['TYPE'].toLowerCase() : this.state.priority }
                changeData={this.changeData }
              />

              {/* BUS BOX */}
              <div className={'box bus-box' + (this.state.busSelected ? '' : ' disabled')}>
                <BoxHeader
                  iconName={'bus'}
                  title={'TRANSPORT'}
                  tooltipDisabled={!this.state.busSelected}
                  infoText={"If an overnight is planned at the destination airport or the route is short enough that can be done by bus, we provide instant quotation and routing from our bus providers in order to accommodate all passengers."}
                />
                <BusBoxResume bodyHeight={bodyTopHeight} />

                <SolutionTypeTitle type={this.state.dataBus['TYPE'] ? this.getTypeColor(this.state.dataBus['TYPE'] ) : this.state.priority } />

                <BoxSolutions
                  amount={this.state.dataBus ? (this.state.dataBus.PRICE ) : ('') }
                  alternativeSolutions={this.state.dataBus ? (this.state.dataBus.SOLUTIONS ) : ('') }
                  checked={this.state.busSelected}
                  onSwitchChange={this.handleBoxes('busSelected')}
                  onModalToggleClick={(e) => {
                    this.setState({busModalVisible: true});
                    e.preventDefault();
                  }}
                  children={ this.state.dataBus.length !== 0  ? (<div dangerouslySetInnerHTML={{__html: this.formatDate(this.state.dataBus.CARD) }} />) : ('')}
                />
              </div>

              <BusModal
                show={this.state.busModalVisible}
                onHide={() => this.setState({busModalVisible: false})}
                priority={this.state.dataHotels['TYPE'] ? this.state.dataHotels['TYPE'].toLowerCase() : this.state.priority }
              />

              {/* LOGISTICS BOX */}
              <div className={'box logistics-box' + (this.state.logisticsSelected ? '' : ' disabled')}>
                <BoxHeader
                  iconName={'cog_double'}
                  title={'LOGISTICS'}
                  tooltipDisabled={!this.state.logisticsSelected}
                  infoText={"Sometimes an AOG can ground a plane in an isolated airport where the airline must send a maintenance team to fix the plane. Thanks to our logistic knowledge we offer the fastest solution, and at the same time the cheapest."}
                />
                <LogisticsBoxResume bodyHeight={bodyTopHeight} />

                <SolutionTypeTitle type={this.state.dataLogistics['TYPE'] ? this.getTypeColor(this.state.dataLogistics['TYPE'] ) : this.state.priority } />

                <BoxSolutions
                  amount={this.state.dataLogistics ? (this.state.dataLogistics.PRICE ) : ('') }
                  alternativeSolutions={this.state.dataLogistics ? (this.state.dataLogistics.SOLUTIONS ) : ('') }
                  checked={this.state.logisticsSelected}
                  onSwitchChange={this.handleBoxes('logisticsSelected')}
                  onModalToggleClick={(e) => {
                    this.setState({logisticsModalVisible: true});
                    e.preventDefault();
                  }}
                  children={this.state.dataLogistics.length !== 0  ? (<div dangerouslySetInnerHTML={{__html: this.formatDate(this.state.dataLogistics.CARD) }} />) : ('')}
                />
              </div>

              <LogisticsModal
                show={this.state.logisticsModalVisible}
                onHide={() => this.setState({logisticsModalVisible: false})}
                dataALTN={ this.getDataResultFromType('Logistics','ALTN')}
                priority={this.state.dataLogistics['TYPE'] ? this.state.dataLogistics['TYPE'].toLowerCase() : this.state.priority }
                changeData={this.changeData }
              />

              {/* OTA BOX */}
              {/*<div className={'box ota-box' + (this.state.otaSelected ? '' : ' disabled')}>
                <BoxHeader
                  iconName={'store'}
                  title={'OTA'}
                  tooltipDisabled={!this.state.otaSelected}
                />
                <OtaBoxResume bodyHeight={bodyTopHeight} />
                <SolutionTypeTitle type={'fastest'} />
                <BoxSolutions
                  amount={'8.369,00'}
                  alternativeSolutions={10}
                  checked={this.state.otaSelected}
                  onSwitchChange={this.handleBoxes('otaSelected')}
                  onModalToggleClick={(e) => {
                    this.setState({otaModalVisible: true});
                    e.preventDefault();
                  }}
                />
                {/*
                  <SolutionTypeTitle />
                  <div className="box-body no-solutions">
                    <p>No solutions available</p>
                  </div>
              </div>
              */}
              <div className={'box ota-box' + (this.state.otaSelected ? '' : ' disabled')}>
                <BoxHeader
                    iconName={'store'}
                    title={'OTA'}
                    tooltipDisabled={!this.state.otaSelected}
                    infoText={"In the event of finding a subcharter with smaller capacity or not able to find an internal solution, we provide an OTA ready to find scheduled flights as fast as possible with other regular carriers, to accommodate all passengers at a blink."}
                />
                <OtaBoxResume bodyHeight={bodyTopHeight} />

                <SolutionTypeTitle type={this.state.dataOta['TYPE'] ? this.getTypeColor(this.state.dataOta['TYPE'] ) : this.state.priority } />

                <BoxSolutions
                    amount={this.state.dataOta ? (this.state.dataOta.PRICE ) : ('') }
                    alternativeSolutions={this.state.dataOta ? (this.state.dataOta.SOLUTIONS ) : ('') }
                    checked={this.state.otaSelected}
                    onSwitchChange={this.handleBoxes('otaSelected')}
                    onModalToggleClick={(e) => {
                      this.setState({otaModalVisible: true});
                      e.preventDefault();
                    }}
                    children={ this.state.dataOta.length !== 0  ? (<div dangerouslySetInnerHTML={{__html: this.formatDate(this.state.dataOta.CARD) }} />) : ('')}
                />
              </div>

              <OtaModal
                show={this.state.otaModalVisible}
                onHide={() => this.setState({otaModalVisible: false})}
                dataALTN={ this.getDataResultFromType('Ota','ALTN')}
                priority={this.state.dataOta['TYPE'] ? this.state.dataOta['TYPE'].toLowerCase() : this.state.priority }
                changeData={this.changeData }
                dateSearch={this.state.dateSearch}
              />

            </div>

            {/* RESULTS SUBMIT */}
            <div className={'box results-submit-row'}>
              <div className={'state-switch-area'}>
                <div className={'state-group time' + (this.state.priority === 'fastest' ? ' active' : '' )}>
                  <Icomoon iconName={'clock'} />
                  <p className={'tit'}>Priorize <span>time</span></p>
                </div>
                <div className={'boxed-item' + (this.state.priority === 'manual' ? ' switch-gray' : '')}>
                  <StyledSwitch checked={this.state.priority === 'price'} onChange={this.handlePriority} />
                </div>
                <div className={'state-group price' + (this.state.priority === 'price' ? ' active' : '' )}>
                  <Icomoon iconName={'money_coins'} />
                  <p className={'tit'}>Priorize <span>price</span></p>
                </div>
              </div>
              <div className={'stat-resume'}>
                <LabelGroup iconName={'person_standing'} text={'PAX Unsolved'} />
                <p style={{ paddingLeft: 4 }}>0</p>
              </div>
              <div className={'stat-resume highlighted' + (this.state.priority === 'fastest' ? ' bg-primary' : '')}>
                <LabelGroup iconName={'calendar'} text={'Lead Time'} />
                <p>{this.state.checkOutLead}</p>
              </div>
              <div className={'stat-resume highlighted' + (this.state.priority === 'fastest' ? ' bg-primary' : '')}>
                <LabelGroup iconName={'clock'} text={'Time to action'} />
                <p>{this.state.checkOutTime}</p>
              </div>
              <div className={'stat-resume highlighted' + (this.state.priority === 'price' ? ' bg-green' : '')}>
                <LabelGroup iconName={'money_coins'} text={'Final Cost'} />
                <p>{this.state.checkOutPrice} €</p>
              </div>
              <LinkButton
                datas={this.getDataForCheckout()}
                value={this.state.priority}
                datasback={this.getDataForBack()}
                text={'Checkout'} iconName={'arrow_right'} color={'black'} to={'/payment'} />
            </div>

          </div>
        </form>
      </main>


    )
  }
}
