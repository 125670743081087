import React, { Component } from 'react';

import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect
} from 'react-router-dom';

import './scss/App.scss';

import Topbar from './components/Topbar';
import Footer from './components/Footer';

import HomeScreen from './screens/HomeScreen';
import LoginScreen from './screens/LoginScreen';
import PaymentScreen from './screens/PaymentScreen';
import ResultsScreen from './screens/ResultsScreen';


export default class App extends Component {

  constructor() {

    super()
    this.state = {
      userIsLogged: false,
      clientLogo: false,
    }
  }

  componentDidMount() {
    document.title = 'Safly';
  }

  getDataFromLogin = (data) => {
    this.setState({
      userIsLogged: true,
      clientLogo: data,
    })
  }

  render() {

    return (
      <div className="App">
        <Topbar loggedUser={this.state.userIsLogged} />

        <Router>
          <Switch>
            <Route exact path={'/'} component={ () => <LoginScreen functionCallFromParent={this.getDataFromLogin} /> } >
            {this.state.userIsLogged ? <Redirect to="/home" /> : '' }
            </Route>
            <Route exact path={'/home'} component={HomeScreen}  />
            <Route exact path={'/results'} component={ResultsScreen} />
            <Route exact path={'/payment'} component={PaymentScreen} />
          </Switch>
        </Router>
        <Footer clientLogo={this.state.clientLogo} />
      </div>
    );
  }
}
