import React, { Component } from 'react';

import RadioGroup from '@material-ui/core/RadioGroup';

import FormControl from '../components/FormControl';
import FormRadio from '../components/FormRadio';
import LabelGroup from '../components/LabelGroup';
import SingleSelect from '../components/SingleSelect';

export default class BusForm extends Component {

  render() {

    const fromSelectOptions = []
    var i = 0;
    if (typeof this.props.dataFromParent.Airports != "undefined"){
        //<10 -> 0i
        for (i = 0; i < this.props.dataFromParent.Airports.length; i++) {
            fromSelectOptions.push( {value:'frm'+i, label: this.props.dataFromParent.Airports[i]} )
        }
    }

    return(
      <div className="box-body">
        <div className={'form-row'}>
          <div className="form-group percent-40">
            <LabelGroup
              iconName={'person_standing'}
              text={'PAX'}
            />
            <FormControl
              disabled={!this.props.boxSelected}
              type={'number'}
            />
          </div>
        </div>

        <div className="form-group">
          <LabelGroup
            iconName={'location_airport_departure'}
            text={'From Airport'}
          />
          <SingleSelect
            disabled={!this.props.boxSelected}
            options={fromSelectOptions}
          />
        </div>

        <div className="form-group">
          <LabelGroup
            iconName={'pin_outline'}
            text={'To'}
          />
          <RadioGroup
            name={'destination'}
            style={{ marginBottom: 10 }}
          >
            <FormRadio
              description={'From "Hotels" vertical solution'}
              disabled={!this.props.boxSelected}
              label={'Selected hotel'}
              value={'hotel'}
            />
            <FormRadio
              disabled={!this.props.boxSelected}
              value={'free'} label={'Free destination'}
            />
          </RadioGroup>
          <FormControl
            disabled={!this.props.boxSelected}
            placeholder={'Destination address'}
          />
        </div>

      </div>
    )
  }
}
