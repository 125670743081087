import React, { Component } from 'react';

import Icomoon from '../components/Icomoon';

export default class Button extends Component {
  render() {
    const { block, color, iconName, onClick, text, title } = this.props;
    return(
      <button
        className={`btn btn-${color}` + (block === true ? ' block' : '')}
        title={title}
        onClick={onClick}
      >
        <span className={'text'}>{text}</span>
        <Icomoon iconName={iconName} className={iconName === false ? ' hidden' : ''} />
      </button>
    )
  }
}

Button.defaultProps = {
  color: 'primary',
  block: false,
  iconName: false
}
