import React, { Component } from 'react';

import Icomoon from '../components/Icomoon';

function CreateSolutionTypeTitle(type) {
  switch(type) {
    case 'fastest':
      return(
        <div className={'solution-type-title bg-primary'}>
          <Icomoon iconName={'clock'} />
          <h3 className={'tit'}>Fastest solution</h3>
        </div>
      );
    case 'price':
      return(
        <div className={'solution-type-title bg-green'}>
          <Icomoon iconName={'money_coins'} />
          <h3 className={'tit'}>Best price</h3>
        </div>
      );
    case 'partial':
      return(
        <div className={'solution-type-title bg-orange'}>
          <Icomoon iconName={'warning_circle'} />
          <h3 className={'tit'}>Partial solution</h3>
        </div>
      );
    case 'alternative':
      return(
        <div className={'solution-type-title bg-yellow'}>
          <Icomoon iconName={'check_circle'} />
          <h3 className={'tit'}>Alternative solution</h3>
        </div>
      );
    default:
      return(
        <div className={'solution-type-title bg-red'}>
          <Icomoon iconName={'cross_circle'} />
          <h3 className={'tit'}>No solution</h3>
        </div>
      );
  }
}

export default class SolutionTypeTitle extends Component {
  render() {
    return CreateSolutionTypeTitle(this.props.type)
  }
}
