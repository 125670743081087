import React, { Component } from 'react';

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

export default class CustomCalendar extends Component {
  constructor() {
    super()
    this.state = {
      selectedDate: null
    }
  }

  updateSelectedDate = val => {
    this.setState({
      selectedDate: val
    }, function(){
      this.props.getDateA( this.state.selectedDate );
    })

  }

  render() {
    return(
      <DatePicker
        className={'form-control'}
        calendarClassName={'custom-calendar'}
        dateFormat="dd/MM/yyyy - H:mm"
        disabled={this.props.disabled}
        onChange={(e) => this.updateSelectedDate(e)}
        placeholderText={'Select date'}
        selected={this.state.selectedDate}
        showTimeSelect
        timeFormat="H:mm aa"
        timeIntervals={60}
        timeCaption="Time"
      />
    )
  }
}

CustomCalendar.defaultProps = {
  disabled: false,
}
