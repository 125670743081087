import React, { Component } from 'react';

import Icon from '@material-ui/core/Icon';

import LabelGroup from '../components/LabelGroup';
import ResultRow from '../components/ResultRow';

export default class HotelsBoxResume extends Component {
    constructor(props) {
        super(props)
        this.state = {
        }
    }

  render() {

    return(
      <div className={'box-body'} style={{ height: this.props.bodyHeight }}>
        <div className={'form-row'}>
          <div className={'form-group percent-50'}>
            <LabelGroup
              iconName={'city'}
              text={'Airport'}
            />
            <p>NTE - Nantes</p>
          </div>
          <div className={'form-group percent-50'}>
            <LabelGroup
              iconName={'location_pin_area'}
              text={'Km range'}
            />
            <p>20</p>
          </div>
        </div>
        <div className={'form-group'}>
          <LabelGroup
            iconName={'star'}
            text={'Categories'}
          />
          <div className={'tags-row'}>
            <div className="tag boxed-item">
              <div className={'stars-row'}>
                <Icon>star</Icon>
                <Icon>star</Icon>
                <Icon>star</Icon>
              </div>
            </div>
            <div className="tag boxed-item">
              <div className={'stars-row'}>
                <Icon>star</Icon>
                <Icon>star</Icon>
                <Icon>star</Icon>
                <Icon>star</Icon>
              </div>
            </div>
          </div>
        </div>
        <div className={'form-group'}>
          <LabelGroup
            iconName={'hotel_bed'}
            text={'Room type'}
          />
          <div className={'results-list'}>
            <ResultRow
              term={'40 PAX'}
              description={'Individual'}
            />
            <ResultRow
              term={'140 PAX'}
              description={'For 2'}
            />
          </div>
          <h2 className="total-title">Total</h2>
          <ResultRow
            term={'180 PAX'}
            description={'110 Rooms'}
          />
        </div>
      </div>
    )
  }
}
