import React, { Component } from 'react';

import Icomoon from '../components/Icomoon';

export default class LabelGroup extends Component {
  render() {
    const { iconName, text } = this.props;
    return(
      <div className={'label-group'}>
        <Icomoon iconName={iconName} />
        <p className={'form-label'}>{ text }</p>
      </div>
    )
  }
}
