import React, { Component } from 'react';

import { withStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';

const CreateStyledSwitch = withStyles(theme => ({
  root: {
    width: 32,
    height: 20,
    padding: 0,
    display: 'flex',
  },
  switchBase: {
    padding: 2,
    color: '#BDC3C7',
    '&$checked': {
      transform: 'translateX(16px)',
      color: '#2ECC71',
      '& + $track': {
        opacity: 1,
        backgroundColor: 'transparent'
      },
    },
  },
  thumb: {
    width: 8,
    height: 8,
    boxShadow: 'none',
    position: 'relative',
    top: 4,
    transform: 'translateX(2px)',
  },
  track: {
    border: '2px solid #95A5A6',
    borderRadius: 20 / 2,
    opacity: 1,
    backgroundColor: 'transparent'
  },
  checked: {},
}))(Switch);

export default class StyledSwitch extends Component {
  render() {
    return(
      <CreateStyledSwitch
        className={'styled-switch'}
        checked={this.props.checked}
        onChange={this.props.onChange}
        disableRipple
      />
    )
  }
}
