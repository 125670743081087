import React, { Component } from 'react';

import Icon from '@material-ui/core/Icon';
import Modal from 'react-bootstrap/Modal'

import Button from '../components/Button';
import Icomoon from '../components/Icomoon';
import PriceGroup from '../components/PriceGroup';

export default class BusModal extends Component {
  constructor() {
    super()
    this.state = {
      fastestSelected: false,
      bestPriceSelected: false,
      alternative1aSelected: false,
      alternative1bSelected: false,
      alternative2aSelected: false,
      alternative2bSelected: false,
      BusPriority: 'fastest',
    }
  }

  componentWillReceiveProps(){

    if(this.props.priority ){
      this.setState({BusPriority: this.props.priority});
      if(this.state.BusPriority === 'fastest'){
        this.setState({fastestSelected: true});
        this.setState({bestPriceSelected: false});
        this.setState({alternative1aSelected: false});
        this.setState({alternative1bSelected: false});
        this.setState({alternative2aSelected: false});
        this.setState({alternative2bSelected: false});

      }else if(this.state.BusPriority === 'price'){
        this.setState({fastestSelected: false});
        this.setState({bestPriceSelected: true});
        this.setState({alternative1aSelected: false});
        this.setState({alternative1bSelected: false});
        this.setState({alternative2aSelected: false});
        this.setState({alternative2bSelected: false});

      }else if(this.state.BusPriority === 'manual'){
        this.setState({fastestSelected: false});
        this.setState({bestPriceSelected: false});
        this.setState({alternative1aSelected: true});
        this.setState({alternative1bSelected: false});
        this.setState({alternative2aSelected: false});
        this.setState({alternative2bSelected: false});
      }
    }
    return true;
  }

  handleSelectedSolution = (name) => (e) => {
    e.preventDefault()
    this.setState({
      fastestSelected: false,
      bestPriceSelected: false,
      alternative1aSelected: false,
      alternative1bSelected: false,
      alternative2aSelected: false,
      alternative2bSelected: false,
      [name]: true
    })
  }

  render() {
    return(
      <Modal
        show={this.props.show}
        onHide={this.props.onHide}
        dialogClassName="hotels-modal"
        size={'xl'}
      >
        <Modal.Header closeButton>
          <div className={'modal-solution-header'}>
            <div className={'flex-row'}>
              <Icomoon iconName={'bus'} />
              <Modal.Title className="tit"><strong>Bus</strong> - Solutions</Modal.Title>
            </div>
            <Button
              text={'Apply selected solution'}
              color={'orange'}
              iconName={'check'}
              onClick={this.props.onHide}
            />
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className={'table-responsive'}>
            <table className={'table align-cells-top'}>
              <thead>
                <tr>
                  <th>Company</th>
                  <th>Routing</th>
                  <th>Bus</th>
                  <th>Km</th>
                  <th>Time to action</th>
                  <th>PAX</th>
                  <th className={'text-center'}>Price</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td colSpan={7} className={'title-td bg-primary'}>
                      <span className="flex-row">
                        <Icon>access_time</Icon>
                        <span className={'tit'}>Fastest Solution</span>
                      </span>
                  </td>
                </tr>
                <tr className={'strong'}>
                  <td>
                      TRANSDEV CTA
                  </td>
                  <td>
                    · NTE AIRPORT<br/>
                    · CAMPANILE NANTES SUD
                  </td>
                  <td>
                    2 BUX, 55 PAX <br/>
                    2 BUS, 25 PAX
                  </td>
                  <td>4,3km</td>
                  <td>1h</td>
                  <td>135</td>
                  <td className={'text-center'}>
                    <PriceGroup price={'840,00'} selected={this.state.fastestSelected} />
                  </td>
                </tr>

                <tr className={'strong'}>
                  <td colSpan={7} className={'title-td bg-green'}>
                      <span className="flex-row">
                        <Icon>money</Icon>
                        <span className={'tit'}>Best Price Solution</span>
                      </span>
                  </td>
                </tr>
                <tr className={'strong'}>
                  <td rowSpan={2}>
                    TRANSDEV CTA
                  </td>
                  <td>
                    · NTE AIRPORT<br/>
                    · HOTEL KYRIAD NANTES EST
                  </td>
                  <td>2 BUS, 55 PAX</td>
                  <td>15,3km</td>
                  <td>1h</td>
                  <td>135</td>
                  <td className={'text-center'} rowSpan={3}>
                    <PriceGroup price={'1.500,00'} selected={this.state.bestPriceSelected}  />
                  </td>
                </tr>
                <tr className={'strong'}>
                  <td>
                    · NTE AIRPORT<br/>
                    · HOTEL INN DESIGN
                  </td>
                  <td>2 BUS, 55 PAX
                  </td>
                  <td>14,3km</td>
                  <td>1h</td>
                  <td>95</td>
                </tr>
                <tr className={'strong'}>
                  <td>TAXI</td>
                  <td>
                    · NTE AIRPORT<br/>
                    · CAMPANILE NANTES SUD
                  </td>
                  <td>1 TAXI</td>
                  <td>10,8km</td>
                  <td>30min</td>
                  <td>3</td>
                </tr>

                <tr>
                  <td colSpan={7} className={'title-td bg-yellow'} style={{ paddingTop: 5, paddingBottom: 5 }}>
                      <span className="flex-row">
                        <Icon fontSize={'small'}>check_circle_outline</Icon>
                        <span className={'tit'}>Alternative Solutions</span>
                      </span>
                  </td>
                </tr>
                <tr className={'strong'}>
                  <td colSpan={7}>AUGEREAU AUTOCARS</td>
                </tr>
                <tr>
                  <td>
                    <div className="ico-text text-primary">
                      <Icon>access_time</Icon>
                      <strong>FASTEST<br/>SOLUTION</strong>
                    </div>
                  </td>
                  <td>
                    · NTE AIRPORT<br/>
                    · CAMPANILE NANTES SUD
                  </td>
                  <td>
                    2 BUX, 55 PAX <br/>
                    2 BUS, 25 PAX
                  </td>
                  <td>4,3km</td>
                  <td>1h</td>
                  <td>135</td>
                  <td className={'text-center'}>
                    <PriceGroup price={'1.000,00'} selected={this.state.alternative1aSelected} />
                  </td>
                </tr>
                <tr>
                  <td rowSpan={2}>
                    <div className="ico-text text-green">
                      <Icon>money</Icon>
                      <strong>BEST<br/>PRICE</strong>
                    </div>
                  </td>
                  <td>
                    · NTE AIRPORT<br/>
                    · HOTEL KYRIAD NANTES EST
                  </td>
                  <td>2 BUX, 55 PAX</td>
                  <td>15,3km</td>
                  <td>2h</td>
                  <td>135</td>
                  <td className={'text-center'} rowSpan={2}>
                    <PriceGroup price={'1.700,00'} selected={this.state.alternative1bSelected} />
                  </td>
                </tr>
                <tr>
                  <td>
                    · NTE AIRPORT<br/>
                    · HOTEL INN DESIGN<br/>
                    · CAMPANILE NANTES EST
                  </td>
                  <td>2 BUX, 50 PAX</td>
                  <td>15,3km</td>
                  <td>2h</td>
                  <td>75</td>
                </tr>


                <tr className={'separator'}>
                  <td colSpan={7}>&nbsp;</td>
                </tr>
                <tr className={'strong'}>
                  <td colSpan={7}>KEOLIS ATLANTIQUE</td>
                </tr>
                <tr>
                  <td>
                    <div className="ico-text text-primary">
                      <Icon>access_time</Icon>
                      <strong>FASTEST<br/>SOLUTION</strong>
                    </div>
                  </td>
                  <td>
                    · NTE AIRPORT<br/>
                    · CAMPANILE NANTES SUD
                  </td>
                  <td>
                    2 BUX, 55 PAX <br/>
                    2 BUS, 25 PAX
                  </td>
                  <td>4,3km</td>
                  <td>1h</td>
                  <td>135</td>
                  <td className={'text-center'}>
                    <PriceGroup price={'1.000,00'} selected={this.state.alternative2aSelected}  />
                  </td>
                </tr>
                <tr>
                  <td rowSpan={2}>
                    <div className="ico-text text-green">
                      <Icon>money</Icon>
                      <strong>BEST<br/>PRICE</strong>
                    </div>
                  </td>
                  <td>
                    · NTE AIRPORT<br/>
                    · HOTEL KYRIAD NANTES EST
                  </td>
                  <td>2 BUX, 55 PAX</td>
                  <td>15,3km</td>
                  <td>2h</td>
                  <td>135</td>
                  <td className={'text-center'} rowSpan={2}>
                    <PriceGroup price={'1.700,00'} selected={this.state.alternative2bSelected}/>
                  </td>
                </tr>
                <tr>
                  <td>
                    · NTE AIRPORT<br/>
                    · HOTEL INN DESIGN<br/>
                    · CAMPANILE NANTES EST
                  </td>
                  <td>2 BUX, 50 PAX</td>
                  <td>15,3km</td>
                  <td>2h</td>
                  <td>75</td>
                </tr>

              </tbody>
            </table>
          </div>
        </Modal.Body>
      </Modal>
    )
  }
}
